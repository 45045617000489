import React, { useEffect } from "react";
import NotFound from "./components/notFound/NotFound";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Home from "./page/home/Home";
import Support from "./page/support/Support";
import PrivacyPolicy from "./page/privacyPolicy/PrivacyPolicy";
import AboutUs from "./page/aboutUs/AboutUs";
import LoginAdmin from "./admin/LoginAdmin";
import Dashboard from "./admin/pages/dashboard/Dashboard";
import Navigation from "./admin/navigation/Navigation";
import { useAppContext } from "./components/context/AppProvider";
import Help from "./admin/pages/help/Help";
import SocialMedia from "./admin/pages/socialMedia/SocialMedia";
import AdminPrivacyPolicy from "./admin/pages/adminPrivacyPolicy/AdminPrivacyPolicy";
import AdminAboutUs from "./admin/pages/adminAboutUs/AdminAboutUs";
import Settings from "./admin/pages/settings/Settings";
import AdminSlider from "./admin/pages/adminSlider/AdminSlider";
import ChampionshipCategory from "./admin/pages/championship/championshipCategory/ChampionshipCategory";
import AddChampionship from "./admin/pages/championship/addChampionship/AddChampionship";
import AllChampionship from "./admin/pages/championship/addChampionship/AllChampionship";
import Championship from "./page/championship/Championship";
import Store from "./page/store/Store";
import AllStore from "./admin/pages/store/addStore/AllStore";
import AddStore from "./admin/pages/store/addStore/AddStore";
import StoreCategory from "./admin/pages/store/storeCategory/StoreCategory";
import ViewStore from "./page/store/view/ViewStore";
import Login from "./page/auth/Login";
import Register from "./page/auth/Register";
import ForgotPassword from "./page/auth/ForgotPassword";
import UserDashboard from "./page/userDashboard/homeDashboard/UserDashboard";
import PurchaseHistory from "./page/userDashboard/purchaseHistory/PurchaseHistory";
import Customers from "./admin/pages/customers/Customers";
import SuccessfulPayment from "./page/responsePayment/SuccessfulPayment";
import ErrorPayment from "./page/responsePayment/ErrorPayment";
import ViewCustomers from "./admin/pages/customers/ViewCustomers";
import AdminPurchaseHistory from "./admin/pages/purchaseHistory/AdminPurchaseHistory";

const App = () => {
  const location = useLocation();
  const context = useAppContext();

  useEffect(() => {
    window.scrollTo(0, 0);

    setTimeout(() => {
      if (location.hash) {
        const targetId = location.hash.substring(1);
        const targetElement = document.getElementById(targetId);
        const height = 100;

        if (targetElement) {
          const offset = height;
          const elementTop =
            targetElement.getBoundingClientRect().top + window.scrollY;
          const offsetTop = elementTop - offset;

          window.scrollTo({ top: offsetTop, behavior: "smooth" });
        }
      }
    }, 300);
  }, [location.pathname, location.hash]);

  return (
    <div
      className={`direction direction_${context.direction}`}
      style={{ direction: context.direction }}
    >
      <Routes>
        <Route path="/admin" element={<LoginAdmin />} />

        <Route path="/dashboard" element={<Navigation />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="settings" element={<Settings />} />
          <Route path="admin-social-media" element={<SocialMedia />} />
          <Route path="admin-privacy-policy" element={<AdminPrivacyPolicy />} />
          <Route path="admin-about-us" element={<AdminAboutUs />} />
          <Route path="admin-slider" element={<AdminSlider />} />
          <Route path="admin-help" element={<Help />} />
          <Route path="admin-customers" element={<Customers />} />
          <Route
            path="admin-customer/:idCustomer"
            element={<ViewCustomers />}
          />
          <Route
            path="admin-championship-category"
            element={<ChampionshipCategory />}
          />
          <Route path="admin-add-championship" element={<AddChampionship />} />
          <Route path="admin-championship" element={<AllChampionship />} />

          <Route path="admin-store" element={<AllStore />} />
          <Route path="admin-add-store" element={<AddStore />} />
          <Route path="admin-store-category" element={<StoreCategory />} />

          <Route
            path="admin-purchase-history"
            element={<AdminPurchaseHistory />}
          />
        </Route>

        <Route path="/" element={<Navbar />}>
          <Route path="/" element={<Home />} />
          <Route path="support" element={<Support />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="about-us" element={<AboutUs />} />

          <Route path="store" element={<Store />} />

          <Route path="championship/:itemId" element={<Championship />} />
          <Route path="store/:itemId" element={<ViewStore />} />

          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forgot-password" element={<ForgotPassword />} />

          <Route path="user-dashboard" element={<UserDashboard />} />
          <Route path="purchase-history" element={<PurchaseHistory />} />

          <Route path="successful-payment" element={<SuccessfulPayment />} />
          <Route path="error-payment" element={<ErrorPayment />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
