import React from "react";
import ComingSoon from "./view/ComingSoon";
import Confirmed from "./view/Confirmed";

const HomeChampionship = () => {
  return (
    <>
      <Confirmed />
      <ComingSoon />
    </>
  );
};

export default HomeChampionship;
