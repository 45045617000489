import React, { useEffect, useRef, useState } from "react";
import ViewData from "../../../components/services/ViewData";
import Loading from "../../../components/loading/Loading";
import { toast } from "react-toastify";
import DeleteData from "../../../components/services/DeleteData";
import { MdOutlineDateRange } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";
import "./customers.css";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";

const Customers = () => {
  const [viewCustomer, setviewCustomer] = useState([]);
  const [customerCount, setCustomerCount] = useState(0);
  const [isDisabledLoadMore, setDisabledLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const navigate = useNavigate();
  const trueRef = useRef(true);

  const dataCustomer = async () => {
    setLoading(true);
    setCurrentPage(1);

    try {
      const result = await ViewData("customers/customers.php");
      setviewCustomer(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataCustomerCount = async () => {
    setLoading(true);

    try {
      const result = await ViewData("customers/customers_count.php");
      setCustomerCount(result.request);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataCustomerLoadMore = async () => {
    setDisabledLoadMore(true);
    try {
      const result = await ViewData(
        "Customer/Customer.php",
        null,
        currentPage + 1
      );
      setTimeout(() => {
        setviewCustomer((prevData) => prevData.concat(result));

        setDisabledLoadMore(false);

        setCurrentPage((prevPage) => prevPage + 1);
        setLoading(false);
      }, 2000);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      dataCustomer();
      dataCustomerCount();
      trueRef.current = false;
    }
  }, []);

  const handleDelete = async (itemId) => {
    try {
      const result = await DeleteData("customers.php", itemId);

      if (result.request == "successfully") {
        toast.success("Deleted successfully");
        dataCustomer();
        dataCustomerCount();
      } else {
        toast.error("An error occurred");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleView = async (itemId) => {
    navigate(`/dashboard/admin-customer/${itemId}`);
  };

  //   console.log(viewCustomer);
  //   console.log(customerCount);

  return (
    <div>
      <h1>All Customers</h1>

      {error ? (
        "Error"
      ) : load ? (
        <Loading />
      ) : (
        <div className="full_championship customers">
          <div className="all_championship">
            {viewCustomer && viewCustomer.length > 0 ? (
              viewCustomer?.map((item, i) => (
                <div className="item_championship" key={i}>
                  <small className="small_1">
                    <span>Full name:</span> {item.full_name}
                  </small>
                  <small className="small_1">
                    <span>Email:</span> {item.email_user}
                  </small>
                  <small className="small_1">
                    <span>Phone number:</span> {item.phone_number}
                  </small>
                  <small>
                    <MdOutlineDateRange /> {item.date_add}
                  </small>

                  <div className="submit_delete">
                    <IoMdCloseCircle
                      onClick={() => handleDelete(item.id)}
                      className="delete"
                    />

                    <FaEye
                      onClick={() => handleView(item.id)}
                      className="delete"
                    />
                  </div>
                </div>
              ))
            ) : (
              <h1>No Data</h1>
            )}
          </div>

          <div className="div_load_more">
            {viewCustomer &&
              viewCustomer.length > 0 &&
              parseInt(customerCount) !== viewCustomer.length && (
                <button
                  className="button"
                  onClick={dataCustomerLoadMore}
                  disabled={isDisabledLoadMore}
                  style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
                >
                  {!isDisabledLoadMore ? "More" : "loading..."}
                </button>
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Customers;
