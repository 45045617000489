import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import JoditEditor from "jodit-react";

const JoditEditorInput = ({ name, editorConfig, value }) => {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <JoditEditor {...field} value={value} config={editorConfig} />
        )}
      />
    </>
  );
};

export default JoditEditorInput;
