import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { IoMdCloseCircle } from "react-icons/io";
import ViewData from "../../../../components/services/ViewData";
import Loading from "../../../../components/loading/Loading";
import { toast } from "react-toastify";
import Img from "../../../../components/input/Img";
import Text from "../../../../components/input/Text";
import JoditEditorInput from "../../../../components/input/JoditEditorInput";
import Checkbox from "../../../../components/input/Checkbox";
import UpdateData from "../../../../components/services/UpdateData";
import { IoIosAddCircle } from "react-icons/io";
import { IoClose } from "react-icons/io5";

const UpdateChampionship = ({
  dataChampionship,
  dataChampionshipCount,
  itemId,
  setViewItemID,
}) => {
  const [isDisabled, setDisabled] = useState(false);
  const methods = useForm();
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const [isBackgroundImgUpdate, setBackgroundImgUpdate] = useState("");
  const [viewChampionship, setViewChampionship] = useState([]);
  const [viewCategory, setCategory] = useState([]);
  const [viewCategoryChampion, setCategoryChampion] = useState([]);
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const [inputsQuestions, setInputsQuestions] = useState([]);
  const [indexV, setIndexV] = useState(0);
  const [question, setQuestion] = useState(true);
  const trueRef = useRef(true);

  const handleFileChangeUpdate = (e) => {
    setBackgroundImgUpdate(URL.createObjectURL(e.target.files[0]));
  };

  const [editorConfigEN] = useState({
    placeholder: "",
    direction: "ltr",
    minHeight: "500",
  });

  const [editorConfigAR] = useState({
    placeholder: "",
    direction: "rtl",
    minHeight: "500",
  });

  const dataChampionshipID = async () => {
    setLoading(true);
    try {
      const result = await ViewData(
        "championship/championship.php",
        itemId,
        null
      );
      setViewChampionship(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataChampionshipCategory = async () => {
    setLoading(true);

    try {
      const result = await ViewData("championship_category.php");
      setCategory(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataCategoryChampion = async () => {
    setLoading(true);

    try {
      const result = await ViewData("admin_category_champion.php", itemId);
      setCategoryChampion(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataQuestionChampion = async () => {
    setLoading(true);

    try {
      const result = await ViewData("question_champion.php", itemId);
      setInputsQuestions(result);

      if (result !== "") {
        const lastElement = parseInt(result[result.length - 1].id) + 1;

        setIndexV(lastElement);
      }
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      dataChampionshipID();
      dataChampionshipCategory();
      dataCategoryChampion();
      dataQuestionChampion();
      trueRef.current = false;
    }
  }, []);

  const handleClose = async () => {
    dataChampionship();
    dataChampionshipCount();
    setViewItemID(false);
  };

  const onSubmit = async (data, e) => {
    setDisabled(true);

    try {
      const result = await UpdateData("championship.php", e, itemId);

      setDisabled(false);

      if (result.request == "successfully") {
        setDisabled(false);

        toast.success("Updated successfully");

        methods.reset();
        setBackgroundImgUpdate("");

        handleClose();
      } else {
        toast.warning("An problem occurred");

        setDisabled(false);
        console.log(result);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An problem occurred");

      setDisabled(false);
    }
  };

  const addInputQuestions = () => {
    setInputsQuestions([
      ...inputsQuestions,
      {
        id: indexV,
        question_en: "",
        question_ar: "",
      },
    ]);

    setIndexV(indexV + 1);
  };

  const deleteInputQuestions = (id) => {
    setQuestion(false);

    const newInputs = inputsQuestions.filter((input) => input.id !== id);

    setInputsQuestions(newInputs);
    setTimeout(() => {
      setQuestion(true);
    }, 1000);
  };

  // console.log(viewChampionship);
  // console.log(viewCategory);
  // console.log(viewCategoryChampion);
  // console.log(inputsQuestions);
  // console.log(indexV);

  return (
    <div className="position_fixed">
      <IoMdCloseCircle onClick={handleClose} className="handleClose" />

      <>
        {error ? (
          "Error"
        ) : load ? (
          <Loading />
        ) : (
          <div className="div_position_fixed form add_championship update_championship">
            {viewChampionship &&
              viewChampionship.length > 0 &&
              viewChampionship?.map((item, i) => (
                <FormProvider {...methods} key={i}>
                  <form
                    onSubmit={methods.handleSubmit(onSubmit)}
                    encType="multipart/form-data"
                  >
                    <div className="background_upload">
                      <Img
                        name="img"
                        type="file"
                        accept="image/*"
                        required={false}
                        change={handleFileChangeUpdate}
                      />

                      <img
                        src={
                          isBackgroundImgUpdate != ""
                            ? isBackgroundImgUpdate
                            : `${LinkLikeBachend}${item.img}`
                        }
                        alt="background_upload"
                      />
                    </div>

                    <div className="inputBox">
                      <Text
                        name="title_en"
                        type="text"
                        readOnly={false}
                        required={true}
                        placeholder="text en"
                        className="input"
                        value={item.title_en}
                      />

                      <Text
                        name="title_ar"
                        type="text"
                        readOnly={false}
                        required={true}
                        placeholder="text ar"
                        className="input"
                        value={item.title_ar}
                      />

                      <div className="price_currency">
                        <Text
                          name="price"
                          type="number"
                          readOnly={false}
                          required={true}
                          placeholder="price"
                          className="input price"
                          value={item.price}
                        />

                        <span>KWD</span>
                      </div>
                    </div>

                    <fieldset>
                      <legend>description EN</legend>

                      <JoditEditorInput
                        name="description_en"
                        editorConfig={editorConfigEN}
                        value={item.description_en}
                      />
                    </fieldset>

                    <fieldset>
                      <legend>description AR</legend>

                      <JoditEditorInput
                        name="description_ar"
                        editorConfig={editorConfigAR}
                        value={item.description_ar}
                      />
                    </fieldset>

                    <fieldset className="Category">
                      <legend>Certainly or SOON</legend>
                      <label htmlFor={`certainly_or_soon_${item.id}`} key={i}>
                        {methods.watch("certainly_or_soon") == true
                          ? "Certainly"
                          : "SOON"}

                        <Checkbox
                          name="certainly_or_soon"
                          idValiue={`certainly_or_soon_${item.id}`}
                          className="input"
                          value={item.certainly_or_soon == 1 ? true : false}
                        />
                      </label>
                    </fieldset>

                    <fieldset className="Category">
                      <legend>Category</legend>

                      {viewCategory &&
                        viewCategory.length > 0 &&
                        viewCategory?.map((item, i) => {
                          const isChecked =
                            viewCategoryChampion &&
                            viewCategoryChampion.length > 0 &&
                            viewCategoryChampion?.some(
                              (champion) => champion.id_category === item.id
                            );

                          return (
                            <label htmlFor={`category_${item.id}`} key={i}>
                              <Checkbox
                                name={`category_${item.id}`}
                                className="input"
                                idValiue={`category_${item.id}`}
                                value={isChecked}
                              />

                              <small>{item.text_en}</small>
                              <small>{item.text_ar}</small>
                              <small>
                                <img
                                  src={`${LinkLikeBachend}${item.img}`}
                                  alt={item.text_en}
                                />
                              </small>
                            </label>
                          );
                        })}
                    </fieldset>

                    <div className="inputBox">
                      <Text
                        name="date_add"
                        type="date"
                        readOnly={false}
                        required={true}
                        className="input"
                        value={item.date_add}
                      />
                    </div>

                    <fieldset className="Category">
                      <legend>Number of Subscribers</legend>
                      <label htmlFor="number_subscribers">
                        <Text
                          name="number_subscribers"
                          type="number"
                          readOnly={false}
                          required={true}
                          className="input"
                          value={item.number_subscribers}
                        />
                      </label>
                    </fieldset>

                    <div className="info_required_form">
                      <div className="required_form">
                        <fieldset className="Category">
                          <legend>Gender</legend>
                          <label htmlFor="gender_user">
                            {methods.watch("gender_user")
                              ? "required"
                              : "Not required"}
                            <Checkbox
                              name="gender_user"
                              idValiue="gender_user"
                              className="input"
                              value={item.gender_user == 1 ? true : false}
                            />
                          </label>
                        </fieldset>

                        <fieldset className="Category">
                          <legend>Team or individual</legend>
                          <label htmlFor="team_or_individual_user">
                            {methods.watch("team_or_individual_user")
                              ? "required"
                              : "Not required"}
                            <Checkbox
                              name="team_or_individual_user"
                              idValiue="team_or_individual_user"
                              className="input"
                              value={
                                item.team_or_individual_user == 1 ? true : false
                              }
                            />
                          </label>
                        </fieldset>
                      </div>

                      {question ? (
                        inputsQuestions.length > 0 &&
                        inputsQuestions?.map((input, index) => {
                          return (
                            <div className="inputBox" key={input.id}>
                              <Text
                                name={`question_en_${input.id}`}
                                type="text"
                                readOnly={false}
                                required={true}
                                placeholder={`question en ${index + 1}`}
                                className="input"
                                value={input.question_en}
                              />

                              <Text
                                name={`question_ar_${input.id}`}
                                type="text"
                                readOnly={false}
                                required={true}
                                placeholder={`question ar ${index + 1}`}
                                className="input"
                                value={input.question_ar}
                              />
                              <IoClose
                                onClick={() => deleteInputQuestions(input.id)}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <p className="delete_questions">Delete Questions</p>
                      )}

                      <span className="button" onClick={addInputQuestions}>
                        <IoIosAddCircle /> Add Questions
                      </span>
                    </div>

                    <input
                      type="submit"
                      style={{
                        opacity: isDisabled ? 0.3 : 1,
                        background: "var(--bl)",
                      }}
                      disabled={isDisabled}
                      value={isDisabled ? "Loading..." : "Update"}
                      className="button"
                    />
                  </form>
                </FormProvider>
              ))}
          </div>
        )}
      </>
    </div>
  );
};

export default UpdateChampionship;
