import React, { useEffect, useRef, useState } from "react";
import { MdOutlineDateRange } from "react-icons/md";
import Loading from "../../../components/loading/Loading";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import all_category from "../../../img/all_category.webp";
import "../championship.css";
import ViewDataCategory from "../../../components/services/ViewDataCategory";
import ViewData from "../../../components/services/ViewData";

const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

const Confirmed = () => {
  const [Championship, setChampionship] = useState([]);
  const [championshipCount, setChampionshipCount] = useState(0);
  const [isDisabledLoadMore, setDisabledLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewCategory, setCategory] = useState([]);
  const [idCategory, setIdCategory] = useState("");
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const trueRef = useRef(true);

  const dataChampionship = async () => {
    setLoading(true);
    setCurrentPage(1);

    try {
      const result = await ViewDataCategory(
        "championship_category/championship_category.php",
        "1"
      );
      setChampionship(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataChampionshipCount = async (category) => {
    try {
      const result = await ViewDataCategory(
        "championship_category/championship_category_count.php",
        "1",
        null,
        category
      );
      setChampionshipCount(result.request);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataChampionshipCategory = async () => {
    setLoading(true);
    setCurrentPage(1);

    try {
      const result = await ViewData("championship_category.php");
      setCategory(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const handleView = async (itemId) => {
    navigate(`/championship/${itemId}`);
  };

  const dataChampionshipLoadMore = async () => {
    setDisabledLoadMore(true);
    try {
      const result = await ViewDataCategory(
        "championship_category/championship_category.php",
        "1",
        currentPage + 1,
        idCategory
      );

      dataChampionshipCount(idCategory);

      setTimeout(() => {
        setChampionship((prevData) => prevData.concat(result));

        setDisabledLoadMore(false);

        setCurrentPage((prevPage) => prevPage + 1);
        setLoading(false);
      }, 2000);
    } catch (error) {
      setError(error);
    }
  };

  const handleCategory = async (category) => {
    setLoading(true);
    setCurrentPage(1);
    setIdCategory(category);

    try {
      const result = await ViewDataCategory(
        "championship_category/championship_category.php",
        "1",
        null,
        category
      );
      dataChampionshipCount(category);
      setChampionship(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      dataChampionship();
      dataChampionshipCount();
      dataChampionshipCategory();
      trueRef.current = false;
    }
  }, []);

  // console.log(Championship);
  // console.log(championshipCount);
  // console.log(idCategory);
  // console.log(viewCategory);

  return (
    <div className="pages">
      {error ? (
        "Error"
      ) : load ? (
        <Loading />
      ) : (
        <div className="full_championship">
          <h2>{t("confirmed")}</h2>

          <div className="categories">
            <button
              className="category"
              onClick={() => handleCategory("")}
              disabled={isDisabledLoadMore}
              style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
            >
              <img src={all_category} alt="all_category" />
            </button>

            {viewCategory &&
              viewCategory?.length > 0 &&
              viewCategory?.map((item, i) => (
                <button
                  key={i}
                  className="category"
                  onClick={() => handleCategory(item.id)}
                  disabled={isDisabledLoadMore}
                  style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
                >
                  <img
                    src={`${LinkLikeBachend}${item.img}`}
                    alt={item.text_en}
                  />

                  {i18n.language == "en" ? item.text_en : item.text_ar}
                </button>
              ))}
          </div>

          <div className="all_championship">
            {Championship && Championship?.length > 0 ? (
              Championship?.map((item, i) => (
                <div
                  className="item_championship"
                  key={i}
                  onClick={() => handleView(item.id)}
                >
                  <img
                    src={`${LinkLikeBachend}${item.img}`}
                    alt={item.title_en}
                  />

                  {item.category !== null && (
                    <div className="soon">
                      <small>
                        {i18n.language === "en"
                          ? item.category.text_en
                          : item.category.text_ar}
                      </small>
                    </div>
                  )}

                  {item.number_subscribers == 0 && (
                    <div className="number_subscribers">
                      <small>{t("full_subscribers")}</small>
                    </div>
                  )}

                  <small className="small_1">
                    {i18n.language === "en" ? item.title_en : item.title_ar}
                  </small>
                  <small>
                    <MdOutlineDateRange /> {item.date_add}
                  </small>
                </div>
              ))
            ) : (
              <h1>{t("no_data")}</h1>
            )}
          </div>

          <div className="div_load_more">
            {Championship &&
              Championship?.length > 0 &&
              parseInt(championshipCount) !== Championship?.length && (
                <button
                  className="button"
                  onClick={dataChampionshipLoadMore}
                  disabled={isDisabledLoadMore}
                  style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
                >
                  {!isDisabledLoadMore ? t("more") : t("loading")}
                </button>
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Confirmed;
