import React from "react";

const SocialMediaLinks = ({ viewSocialMedia }) => {
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

  return (
    <div className="social_media_links">
      {viewSocialMedia &&
        viewSocialMedia.length > 0 &&
        viewSocialMedia?.map((item, i) => (
          <a className="social_media" target="_blank" key={i} href={item.link}>
            <img
              src={`${LinkLikeBachend}${item.img}`}
              alt={`social_media_${i}`}
            />
          </a>
        ))}
    </div>
  );
};

export default SocialMediaLinks;
