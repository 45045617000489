import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "./socialMedia.css";
import Text from "../../../components/input/Text";
import background_upload from "../../../img/background_upload.webp";
import Img from "../../../components/input/Img";
import { IoMdCloseCircle } from "react-icons/io";
import ViewData from "../../../components/services/ViewData";
import Loading from "../../../components/loading/Loading";
import DeleteData from "../../../components/services/DeleteData";
import CreateData from "../../../components/services/CreateData";

const SocialMedia = () => {
  const [isDisabled, setDisabled] = useState(false);
  const methods = useForm();
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const [isBackgroundImg, setBackgroundImg] = useState("");
  const [viewSocialMedia, setSocialMedia] = useState([]);
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const trueRef = useRef(true);

  const handleFileChange = (e) => {
    setBackgroundImg(URL.createObjectURL(e.target.files[0]));
  };

  const onSubmit = async (data, e) => {
    setDisabled(true);

    try {
      const result = await CreateData("social_media.php", e);

      setDisabled(false);

      if (result.request == "successfully") {
        setDisabled(false);

        toast.success("Added successfully");

        methods.reset();
        setBackgroundImg("");
        dataSocialMedia();
      } else {
        toast.warning("An problem occurred");

        setDisabled(false);
        console.log(result);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An problem occurred");

      setDisabled(false);
    }
  };

  const dataSocialMedia = async () => {
    setLoading(true);

    try {
      const result = await ViewData("social_media.php");
      setSocialMedia(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const handleDelete = async (itemId) => {
    try {
      const result = await DeleteData("social_media.php", itemId);

      if (result.request == "successfully") {
        toast.success("Deleted successfully");
        dataSocialMedia();
      } else {
        toast.error("An error occurred");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      dataSocialMedia();
      trueRef.current = false;
    }
  }, []);

  // console.log(viewSocialMedia);

  return (
    <div className="form">
      <h1>Social Media</h1>

      <div className="box">
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            encType="multipart/form-data"
          >
            <div className="background_upload">
              <Img
                name="img"
                type="file"
                accept="image/*"
                required={true}
                change={handleFileChange}
              />

              <img
                src={
                  isBackgroundImg != "" ? isBackgroundImg : background_upload
                }
                alt="background_upload"
              />
            </div>

            <div className="inputBox">
              <Text
                name="link"
                type="url"
                readOnly={false}
                required={true}
                placeholder="link"
                className="input"
              />
            </div>

            <input
              type="submit"
              style={{ opacity: isDisabled ? 0.3 : 1, background: "var(--bl)" }}
              disabled={isDisabled}
              value="Add"
              className="button"
            />
          </form>
        </FormProvider>
      </div>

      {error ? (
        "Error"
      ) : load ? (
        <Loading />
      ) : (
        <div className="social_media_links">
          {viewSocialMedia && viewSocialMedia.length > 0 ? (
            viewSocialMedia?.map((item, i) => (
              <div className="social_item" key={i}>
                <a className="social_media" target="_blank" href={item.link}>
                  <img
                    src={`${LinkLikeBachend}${item.img}`}
                    alt={`social_media_${i}`}
                  />
                </a>
                <IoMdCloseCircle
                  onClick={() => handleDelete(item.id)}
                  className="delete"
                />
              </div>
            ))
          ) : (
            <h1>No Data</h1>
          )}
        </div>
      )}
    </div>
  );
};

export default SocialMedia;
