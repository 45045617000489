import axios from "axios";
const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
const validation = process.env.REACT_APP_VALIDATION;

const ViewData = async (urlPage, itemId, currentPage) => {
  const formData = new FormData();
  formData.append("validation", validation);

  if (itemId) {
    formData.append("itemId", itemId);
  }

  if (currentPage) {
    formData.append("page", currentPage);
  }

  try {
    const response = await axios.post(
      `${LinkLikeBachend}read/${urlPage}`,
      formData
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default ViewData;
