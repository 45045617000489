import React, { useEffect, useRef, useState } from "react";
import "./dashboard.css";
import { IoPeopleOutline } from "react-icons/io5";
import { TbBuildingStore } from "react-icons/tb";
import { GiChampions } from "react-icons/gi";
import ViewData from "../../../components/services/ViewData";
import Loading from "../../../components/loading/Loading";
import ViewDataCategory from "../../../components/services/ViewDataCategory";
import { FcSalesPerformance } from "react-icons/fc";

const Dashboard = () => {
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const [customerCount, setCustomerCount] = useState(0);
  const [storeCount, setStoreCount] = useState(0);
  const [championshipConfirmedCount, setChampionshipConfirmedCount] =
    useState(0);
  const [championshipSoonCount, setChampionshipSoonCount] = useState(0);
  const [totalStore, setTotalStore] = useState(0);
  const [totalChampionship, setTotalChampionship] = useState(0);
  const trueRef = useRef(true);

  const dataCustomerCount = async () => {
    setLoading(true);

    try {
      const result = await ViewData("customers/customers_count.php");
      setCustomerCount(result.request);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataStoreCount = async () => {
    setLoading(true);

    try {
      const result = await ViewData("store/store_count.php");
      setStoreCount(result.request);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataChampionshipConfirmedCount = async () => {
    setLoading(true);

    try {
      const result = await ViewDataCategory(
        "championship_category/championship_category_count.php",
        "1",
        null
      );
      setChampionshipConfirmedCount(result.request);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataChampionshipSoonCount = async () => {
    setLoading(true);

    try {
      const result = await ViewDataCategory(
        "championship_category/championship_category_count.php",
        "0",
        null
      );
      setChampionshipSoonCount(result.request);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataTotalStore = async () => {
    setLoading(true);

    try {
      const result = await ViewData("total_sales_store.php");

      setTotalStore(result.request);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const datasetTotalChampionship = async () => {
    setLoading(true);

    try {
      const result = await ViewData("total_sales_championship.php");

      setTotalChampionship(result.request);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      dataCustomerCount();
      dataStoreCount();
      dataChampionshipConfirmedCount();
      dataChampionshipSoonCount();
      dataTotalStore();
      datasetTotalChampionship();
      trueRef.current = false;
    }
  }, []);

  return (
    <>
      {error ? (
        "Error"
      ) : load ? (
        <Loading />
      ) : (
        <div className="dashboard">
          <div className="dashboard_deposit">
            <div className="deposit_total">
              <FcSalesPerformance />
              <p>{totalChampionship} KWD</p>

              <small>Total Tournament Sales</small>
            </div>
            <div className="deposit_total">
              <FcSalesPerformance />
              <p>{totalStore} KWD</p>

              <small>Total Product Sales</small>
            </div>
            <div className="deposit_total">
              <IoPeopleOutline />
              <p>{customerCount}</p>

              <small>users number</small>
            </div>
          </div>

          <div className="dashboard_deposit">
            <div className="deposit_total">
              <TbBuildingStore />
              <p>{storeCount}</p>

              <small>Total products</small>
            </div>
            <div className="deposit_total">
              <GiChampions />
              <p>{championshipConfirmedCount}</p>

              <small>Total championships confirmed</small>
            </div>
            <div className="deposit_total">
              <GiChampions />
              <p>{championshipSoonCount}</p>

              <small>Total tournaments coming soon</small>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
