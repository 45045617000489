import React, { useEffect, useRef, useState } from "react";
import Loading from "../../components/loading/Loading";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import all_category from "../../img/all_category.webp";
import "./store.css";
import ViewDataCategory from "../../components/services/ViewDataCategory";
import ViewData from "../../components/services/ViewData";
import HomeSlider from "../home/homeSlider/HomeSlider";
import { BsFillBasket3Fill } from "react-icons/bs";
import { useAppContext } from "../../components/context/AppProvider";
const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

const Store = () => {
  const [store, setStore] = useState([]);
  const [storeCount, setStoreCount] = useState(0);
  const [isDisabledLoadMore, setDisabledLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewCategory, setCategory] = useState([]);
  const [idCategory, setIdCategory] = useState("");
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { handleAddToBasket } = useAppContext();
  const trueRef = useRef(true);

  const dataStore = async () => {
    setLoading(true);
    setCurrentPage(1);

    try {
      const result = await ViewDataCategory(
        "store_category/store_category.php",
        "1"
      );
      setStore(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataStoreCount = async (category) => {
    try {
      const result = await ViewDataCategory(
        "store_category/store_category_count.php",
        "1",
        null,
        category
      );
      setStoreCount(result.request);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataStoreCategory = async () => {
    setLoading(true);
    setCurrentPage(1);

    try {
      const result = await ViewData("store_category.php");
      setCategory(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const handleView = (itemId) => {
    navigate(`/store/${itemId}`);
  };

  const dataStoreLoadMore = async () => {
    setDisabledLoadMore(true);
    try {
      const result = await ViewDataCategory(
        "store_category/store_category.php",
        "1",
        currentPage + 1,
        idCategory
      );

      dataStoreCount(idCategory);

      setTimeout(() => {
        setStore((prevData) => prevData.concat(result));

        setDisabledLoadMore(false);

        setCurrentPage((prevPage) => prevPage + 1);
        setLoading(false);
      }, 2000);
    } catch (error) {
      setError(error);
    }
  };

  const handleCategory = async (category) => {
    setLoading(true);
    setCurrentPage(1);
    setIdCategory(category);

    try {
      const result = await ViewDataCategory(
        "store_category/store_category.php",
        "1",
        null,
        category
      );
      dataStoreCount(category);
      setStore(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      dataStore();
      dataStoreCount();
      dataStoreCategory();
      trueRef.current = false;
    }
  }, []);

  // console.log(store);
  //   console.log(StoreCount);
  //   console.log(idCategory);

  return (
    <>
      <HomeSlider />

      <div className="pages">
        {error ? (
          "Error"
        ) : load ? (
          <Loading />
        ) : (
          <div className="full_store">
            <h2>{t("store")}</h2>

            <div className="categories">
              <button
                className="category"
                onClick={() => handleCategory(1)}
                disabled={isDisabledLoadMore}
                style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
              >
                <img src={all_category} alt="all_category" />
              </button>

              {viewCategory &&
                viewCategory.length > 0 &&
                viewCategory?.map((item, i) => (
                  <button
                    key={i}
                    className="category"
                    onClick={() => handleCategory(item.id)}
                    disabled={isDisabledLoadMore}
                    style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
                  >
                    <img
                      src={`${LinkLikeBachend}${item.img}`}
                      alt={item.text_en}
                    />

                    {i18n.language == "en" ? item.text_en : item.text_ar}
                  </button>
                ))}
            </div>

            <div className="all_championship">
              {store && store?.length > 0 ? (
                store?.map((item, i) => (
                  <div className="item_championship" key={i}>
                    <div
                      className="item_store"
                      onClick={() => handleView(item.id)}
                    >
                      <img
                        src={`${LinkLikeBachend}${item.img}`}
                        alt={item.title_en}
                      />

                      {item.price > 0 && (
                        <div className="soon">
                          <small>
                            {item?.price} {t("currency")}
                          </small>
                        </div>
                      )}

                      <small className="small_1">
                        {i18n.language == "en" ? item.title_en : item.title_ar}
                      </small>
                    </div>

                    <div className="add_basket">
                      <button
                        className="button add_basket"
                        onClick={() => handleAddToBasket(item.id, store)}
                      >
                        {t("add_basket")}
                        <BsFillBasket3Fill />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <h1>{t("no_data")}</h1>
              )}
            </div>

            <div className="div_load_more">
              {store &&
                store?.length > 0 &&
                parseInt(storeCount) !== store?.length && (
                  <button
                    className="button"
                    onClick={dataStoreLoadMore}
                    disabled={isDisabledLoadMore}
                    style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
                  >
                    {!isDisabledLoadMore ? t("more") : t("loading")}
                  </button>
                )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Store;
