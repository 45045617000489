import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Text from "../../../../components/input/Text";
import Img from "../../../../components/input/Img";
import { IoMdCloseCircle } from "react-icons/io";
import ViewData from "../../../../components/services/ViewData";
import Loading from "../../../../components/loading/Loading";
import UpdateData from "../../../../components/services/UpdateData";

const UpdateStoreCategory = ({ dataStoreCategory, itemId, setViewItemID }) => {
  const [isDisabled, setDisabled] = useState(false);
  const methods = useForm();
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const [isBackgroundImgUpdate, setBackgroundImgUpdate] = useState(null);
  const [viewCategoryID, setCategoryID] = useState([]);
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const trueRef = useRef(true);

  const handleFileChangeUpdate = (e) => {
    setBackgroundImgUpdate(URL.createObjectURL(e.target.files[0]));
  };

  const dataStoreCategoryID = async () => {
    setLoading(true);
    try {
      const result = await ViewData("store_category.php", itemId, null);
      setCategoryID(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      dataStoreCategoryID();
      trueRef.current = false;
    }
  }, []);

  const handleClose = async () => {
    dataStoreCategory();
    setViewItemID(false);
  };

  const onUpdate = async (data, e) => {
    setDisabled(true);

    try {
      const result = await UpdateData("store_category.php", e, itemId);

      setDisabled(false);

      if (result.request == "successfully") {
        setDisabled(false);

        toast.success("Updated successfully");

        methods.reset();
        setBackgroundImgUpdate("");
        handleClose();
      } else {
        toast.warning("An problem occurred");

        setDisabled(false);
        console.log(result);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An problem occurred");

      setDisabled(false);
    }
  };

  // console.log(viewCategoryID);

  return (
    <div className="position_fixed">
      <IoMdCloseCircle onClick={handleClose} className="handleClose" />

      {error ? (
        "Error"
      ) : load ? (
        <Loading />
      ) : (
        <div className="all_store_category">
          {viewCategoryID &&
            viewCategoryID.length > 0 &&
            viewCategoryID?.map((item, i) => (
              <FormProvider {...methods} key={i}>
                <form
                  onSubmit={methods.handleSubmit(onUpdate)}
                  encType="multipart/form-data"
                  className="store_category_item"
                >
                  <div className="background_upload">
                    <Img
                      name={`img`}
                      type="file"
                      accept="image/*"
                      required={false}
                      change={handleFileChangeUpdate}
                    />

                    <img
                      src={
                        isBackgroundImgUpdate
                          ? isBackgroundImgUpdate
                          : `${LinkLikeBachend}${item.img}`
                      }
                      alt={`store_category`}
                    />
                  </div>

                  <div className="inputBox">
                    <Text
                      name={`text_en`}
                      type="text"
                      readOnly={false}
                      required={true}
                      placeholder="Category text en"
                      className="input"
                      value={item.text_en}
                    />
                  </div>

                  <div className="inputBox">
                    <Text
                      name={`text_ar`}
                      type="text"
                      readOnly={false}
                      required={true}
                      placeholder="Category text ar"
                      className="input"
                      value={item.text_ar}
                    />
                  </div>

                  <div className="submit_delete">
                    <input
                      type="submit"
                      style={{
                        opacity: isDisabled ? 0.3 : 1,
                        background: "var(--bl)",
                      }}
                      disabled={isDisabled}
                      value={isDisabled ? "Loading..." : "Update"}
                      className="button"
                    />
                  </div>
                </form>
              </FormProvider>
            ))}
        </div>
      )}
    </div>
  );
};

export default UpdateStoreCategory;
