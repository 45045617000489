import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Text from "../components/input/Text";
import "./loginAdmin.css";
import { useAppContext } from "../components/context/AppProvider";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const LoginAdmin = () => {
  const [isDisabled, setDisabled] = useState(false);
  const methods = useForm();
  const context = useAppContext();
  const navigate = useNavigate();
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  useEffect(() => {
    if (context.admin != null) {
      navigate("/dashboard", { replace: true });
    }
  }, []);

  const onSubmit = async (data) => {
    setDisabled(true);

    const formDataAdmin = new FormData();
    formDataAdmin.append("validation", validation);
    formDataAdmin.append("password_admin", data.password_admin);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/login_admin.php`,
        formDataAdmin
      );

      setDisabled(false);

      if (response.data.request == "successfully") {
        toast.success("You have been logged in successfully");

        context.loginAdmin(response.data.newtoken);

        navigate("/dashboard", { replace: true });
      } else if (response.data.request == "password_incorrect") {
        toast.warning("The password is incorrect");
      } else {
        toast.error("Error when sending data");
        console.error(response.data);
      }
    } catch (error) {
      setDisabled(false);
      console.error("Error:", error);
      toast.error("Error when sending data");
    }
  };

  return (
    <div className="login_admin">
      <div className="box">
        <span className="borderLine"></span>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <h2>Sign In Admin</h2>

            <NavLink to="/" className="button">
              Home
            </NavLink>

            <div className="inputBox">
              <Text
                name="password_admin"
                type="password"
                readOnly={false}
                required={true}
                placeholder="password"
              />
            </div>

            <input
              type="submit"
              style={{ opacity: isDisabled ? 0.3 : 1 }}
              disabled={isDisabled}
              value="Login"
            />
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default LoginAdmin;
