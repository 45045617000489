import React, { useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";

const Checkbox = ({ name, className, idValiue, value }) => {
  const { control, setValue, watch } = useFormContext();

  useEffect(() => {
    if (value) {
      setValue(name, value);
    }
  }, [value]);

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <input
            {...field}
            type="checkbox"
            className={className}
            id={idValiue}
            checked={watch(name) || false}
          />
        )}
      />
    </>
  );
};

export default Checkbox;
