import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Fieldset from "../../../components/input/Fieldset";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Text from "../../../components/input/Text";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useAppContext } from "../../../components/context/AppProvider";
import UpdateData from "../../../components/services/UpdateData";
import PhoneInputN from "../../../components/input/PhoneInputN";

const UserDashboard = () => {
  const context = useAppContext();
  const methods = useForm();
  const { t } = useTranslation();
  const [typeText, setTypeText] = useState("password");
  const [isDisabled, setDisabled] = useState(false);
  const trueRef = useRef(true);

  const handleChangepPassword = () => {
    typeText == "password" ? setTypeText("text") : setTypeText("password");
  };

  const onSubmit = async (data, e) => {
    if (methods.watch("phone_number").length > 5) {
      setDisabled(true);

      try {
        const result = await UpdateData("update_user_information.php", e);

        setDisabled(false);

        if (result.request == "successfully") {
          toast.success(t("updated_successfully"));
          methods.setValue("password_user", "");
          context.loginUser(context.dataUser.tokin_user);
        } else if (result.request == "user_incorrect") {
          context.logOutUser();
        } else {
          toast.error(t("an_error_occurred"));
          console.log(result);
        }
      } catch (error) {
        toast.error(t("an_error_occurred"));
        setDisabled(false);
        console.log(error);
      }
    } else {
      toast.warning(t("phone_number_failed"));
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      context.privateRoute();
      trueRef.current = false;
    }
  }, []);

  // console.log(context.dataUser);

  return (
    <div className="pages">
      <div className="pages support">
        <div className="left_right">
          <div className="right">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Fieldset
                  type="text"
                  name="full_name"
                  placeholder={t("full_name")}
                  required={true}
                  value={context.dataUser.full_name}
                />

                <PhoneInputN
                  name="phone_number"
                  placeholder={t("phone_number")}
                  required={true}
                  value={context.dataUser.phone_number}
                />

                <Fieldset
                  type="email"
                  name="email_user"
                  placeholder={t("email")}
                  required={true}
                  readOnly={true}
                  value={context.dataUser.email_user}
                />
                <div className="date_of_registration">
                  <small>{t("date_of_registration")}</small>
                  <small>{context.dataUser.date_add}</small>
                </div>

                <div className="password_eye">
                  <Text
                    type={typeText}
                    name="password_user"
                    placeholder={t("change_password")}
                    required={false}
                  />

                  {typeText == "password" ? (
                    <FaEye onClick={handleChangepPassword} />
                  ) : (
                    <FaEyeSlash onClick={handleChangepPassword} />
                  )}
                </div>
                <button
                  type="submit"
                  className="button"
                  disabled={isDisabled}
                  style={{ opacity: isDisabled ? 0.3 : "" }}
                >
                  {isDisabled ? t("waiting") : t("save_data")}
                </button>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
