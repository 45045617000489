import React from "react";
import { useFormContext, Controller } from "react-hook-form";

const Img = ({ name, type, required, className, accept, change }) => {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <input
            {...field}
            type={type}
            accept={accept}
            required={required}
            className={className}
            onChange={(e) => {
              field.onChange(e);
              change(e);
            }}
          />
        )}
      />
    </>
  );
};

export default Img;
