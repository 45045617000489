import React, { useState } from "react";
import { toast } from "react-toastify";
import { IoMdCloseCircle } from "react-icons/io";
import { MdOutlineEdit } from "react-icons/md";
import UpdatechampionshipCategory from "./UpdatechampionshipCategory";
import DeleteData from "../../../../components/services/DeleteData";

const ViewchampionshipCategory = ({
  viewCategory,
  dataChampionshipCategory,
}) => {
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const [itemID, setItemID] = useState(false);
  const [viewItemID, setViewItemID] = useState(false);

  const handleDelete = async (itemId) => {
    try {
      const result = await DeleteData("championship_category.php", itemId);

      if (result.request == "successfully") {
        toast.success("Deleted successfully");
        dataChampionshipCategory();
      } else {
        toast.error("An error occurred");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async (itemId) => {
    setItemID(itemId);
    setViewItemID(true);
  };

  return (
    <>
      {viewItemID && (
        <UpdatechampionshipCategory
          dataChampionshipCategory={dataChampionshipCategory}
          itemId={itemID}
          setViewItemID={setViewItemID}
        />
      )}

      <div className="all_championshipC_category">
        {viewCategory &&
          viewCategory.length > 0 &&
          viewCategory?.map((item, i) => (
            <div className="championship_category_item" key={i}>
              <div className="background_upload">
                <img
                  src={`${LinkLikeBachend}${item.img}`}
                  alt={`championshipC_category_${i}`}
                />
              </div>

              <div className="inputBox">
                <p>{item.text_en}</p>
              </div>

              <div className="inputBox">
                <p>{item.text_ar}</p>
              </div>

              <div className="submit_delete">
                <MdOutlineEdit
                  onClick={() => handleUpdate(item.id)}
                  className="delete"
                />

                <IoMdCloseCircle
                  onClick={() => handleDelete(item.id)}
                  className="delete"
                />
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default ViewchampionshipCategory;
