import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import background_upload from "../../../../img/background_upload.webp";
import Img from "../../../../components/input/Img";
import Text from "../../../../components/input/Text";
import "./addChampionship.css";
import JoditEditorInput from "../../../../components/input/JoditEditorInput";
import ViewData from "../../../../components/services/ViewData";
import Loading from "../../../../components/loading/Loading";
import CreateData from "../../../../components/services/CreateData";
import { IoIosAddCircle } from "react-icons/io";
import { IoClose } from "react-icons/io5";

const AddChampionship = () => {
  const [isDisabled, setDisabled] = useState(false);
  const methods = useForm();
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const [isBackgroundImg, setBackgroundImg] = useState("");
  const [viewCategory, setCategory] = useState([]);
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const [inputsQuestions, setInputsQuestions] = useState([]);
  const [question, setQuestion] = useState(true);
  const [indexV, setIndexV] = useState(0);
  const trueRef = useRef(true);

  const handleFileChange = (e) => {
    setBackgroundImg(URL.createObjectURL(e.target.files[0]));
  };

  const [editorConfigEN] = useState({
    placeholder: "",
    direction: "ltr",
    minHeight: "500",
  });

  const [editorConfigAR] = useState({
    placeholder: "",
    direction: "rtl",
    minHeight: "500",
  });

  const dataChampionshipCategory = async () => {
    setLoading(true);

    try {
      const result = await ViewData("championship_category.php");
      setCategory(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      dataChampionshipCategory();
      trueRef.current = false;
    }
  }, []);

  const onSubmit = async (data, e) => {
    setDisabled(true);

    try {
      const result = await CreateData("add_championship.php", e);

      setDisabled(false);

      if (result.request == "successfully") {
        setDisabled(false);

        toast.success("Added successfully");

        methods.reset();
        setBackgroundImg("");

        dataChampionshipCategory();
        setInputsQuestions([]);
      } else {
        toast.warning("An problem occurred");

        setDisabled(false);
        console.log(result);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An problem occurred");

      setDisabled(false);
    }
  };

  const addInputQuestions = () => {
    setInputsQuestions([
      ...inputsQuestions,
      {
        id: indexV,
        question_en: `question_en_${indexV}`,
        question_ar: `question_ar_${indexV}`,
      },
    ]);

    setIndexV(indexV + 1);
  };

  const deleteInputQuestions = (id) => {
    setQuestion(false);
    const newInputs = inputsQuestions.filter((input) => input.id !== id);
    setInputsQuestions(newInputs);

    setTimeout(() => {
      setQuestion(true);
    }, 1000);
  };

  // console.log(viewCategory);

  return (
    <div className="form add_championship">
      <h1>Add Championship</h1>

      {error ? (
        "Error"
      ) : load ? (
        <Loading />
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            encType="multipart/form-data"
          >
            <div className="background_upload">
              <Img
                name="img"
                type="file"
                accept="image/*"
                required={true}
                change={handleFileChange}
              />

              <img
                src={
                  isBackgroundImg != "" ? isBackgroundImg : background_upload
                }
                alt="background_upload"
              />
            </div>

            <div className="inputBox">
              <Text
                name="title_en"
                type="text"
                readOnly={false}
                required={true}
                placeholder="text en"
                className="input"
              />

              <Text
                name="title_ar"
                type="text"
                readOnly={false}
                required={true}
                placeholder="text ar"
                className="input"
              />

              <div className="price_currency">
                <Text
                  name="price"
                  type="number"
                  readOnly={false}
                  required={true}
                  placeholder="price"
                  className="input price"
                />

                <span>KWD</span>
              </div>
            </div>

            <fieldset>
              <legend>description EN</legend>

              <JoditEditorInput
                name="description_en"
                editorConfig={editorConfigEN}
              />
            </fieldset>

            <fieldset>
              <legend>description AR</legend>

              <JoditEditorInput
                name="description_ar"
                editorConfig={editorConfigAR}
              />
            </fieldset>

            <fieldset className="Category">
              <legend>Certainly or SOON</legend>
              <label htmlFor="certainly_or_soon">
                {methods.watch("certainly_or_soon") ? "Certainly" : "SOON"}
                <Text
                  name="certainly_or_soon"
                  type="checkbox"
                  readOnly={false}
                  required={false}
                  placeholder="Certainly or SOON"
                  className="input"
                  idValiue="certainly_or_soon"
                />
              </label>
            </fieldset>

            <fieldset className="Category">
              <legend>Category</legend>

              {viewCategory &&
                viewCategory.length > 0 &&
                viewCategory?.map((item, i) => (
                  <label htmlFor={`category_${item.id}`} key={i}>
                    <Text
                      name={`category_${item.id}`}
                      type="checkbox"
                      readOnly={false}
                      required={false}
                      placeholder="Certainly or SOON"
                      className="input"
                      idValiue={`category_${item.id}`}
                    />

                    <small>{item.text_en}</small>
                    <small>{item.text_ar}</small>
                    <small>
                      <img
                        src={`${LinkLikeBachend}${item.img}`}
                        alt={item.text_en}
                      />
                    </small>
                  </label>
                ))}
            </fieldset>

            <div className="inputBox">
              <Text
                name="date_add"
                type="date"
                readOnly={false}
                required={true}
                className="input"
              />
            </div>

            <fieldset className="Category">
              <legend>Number of Subscribers</legend>
              <label htmlFor="number_subscribers">
                <Text
                  name="number_subscribers"
                  type="number"
                  readOnly={false}
                  required={false}
                  placeholder="Number of subscribers"
                  className="input"
                  idValiue="number_subscribers"
                />
              </label>
            </fieldset>

            <div className="info_required_form">
              <div className="required_form">
                <fieldset className="Category">
                  <legend>Gender</legend>
                  <label htmlFor="gender_user">
                    {methods.watch("gender_user") ? "required" : "Not required"}
                    <Text
                      name="gender_user"
                      type="checkbox"
                      readOnly={false}
                      required={false}
                      placeholder="gender_user"
                      className="input"
                      idValiue="gender_user"
                    />
                  </label>
                </fieldset>

                <fieldset className="Category">
                  <legend>Team or individual</legend>
                  <label htmlFor="team_or_individual_user">
                    {methods.watch("team_or_individual_user")
                      ? "required"
                      : "Not required"}
                    <Text
                      name="team_or_individual_user"
                      type="checkbox"
                      readOnly={false}
                      required={false}
                      placeholder="team_or_individual_user"
                      className="input"
                      idValiue="team_or_individual_user"
                    />
                  </label>
                </fieldset>
              </div>

              {question ? (
                inputsQuestions?.map((input, index) => (
                  <div className="inputBox" key={input.id}>
                    <Text
                      name={input.question_en}
                      type="text"
                      readOnly={false}
                      required={true}
                      placeholder={`question en ${index + 1}`}
                      className="input"
                    />

                    <Text
                      name={input.question_ar}
                      type="text"
                      readOnly={false}
                      required={true}
                      placeholder={`question ar ${index + 1}`}
                      className="input"
                    />

                    <IoClose onClick={() => deleteInputQuestions(input.id)} />
                  </div>
                ))
              ) : (
                <p className="delete_questions">Delete Questions</p>
              )}

              <span className="button" onClick={addInputQuestions}>
                <IoIosAddCircle /> Add Questions
              </span>
            </div>

            <input
              type="submit"
              style={{
                opacity: isDisabled ? 0.3 : 1,
                background: "var(--bl)",
              }}
              disabled={isDisabled}
              value={isDisabled ? "Loading..." : "Add"}
              className="button"
            />
          </form>
        </FormProvider>
      )}
    </div>
  );
};

export default AddChampionship;
