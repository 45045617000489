export const arabicDataAr = {
  home: "البطولات",
  about_us: "من نحن",
  privacy_policy: "سياسة الخصوصية",
  store: "المتجر",
  support_assistance: "اتصل بنا",
  full_name: "الاسم الكامل",
  phone_number: "رقم الجوال",
  phone_number_failed: "يجب ادخال رقم هاتف صالح",
  email: "البريد الاكتروني",
  message: "الرسالة",
  submit: "تسجيل",
  send_message: "أرسال",
  waiting: "انتظار",
  has_been_sent: "تم الارسال",
  error_occurred_while_sending: "حدث خطأ عند الارسال",
  currency: "دينار كويتي",
  coming_soon: "قريباً",
  confirmed: "مؤكدة",
  more: "المزيد",
  loading: "تحميل...",
  no_data: "لا يوجد بيانات",
  add_basket: "اضف للسلة",
  already_inside_cart: "المنتج موجود بالفعل داخل السلة",
  an_error_occurred: "حدث خطأ",
  total: "الاجمالي",
  basket_empty: "السلة فارغة",
  pay: "دفع",
  password: "كلمة المرور",
  user_not_registered: "المستخدم غير مسجل",
  the_password_incorrect: "كلمة المرور غير صحيحه",
  register_account: "تسجيل حساب جديد",
  forgot_your_password: "نسيت كلمة المرور",
  email_not_confirmation: "الحساب غير مفعل",
  login: "تسجيل دخول",
  email_already_registered: "البريد مسجل من قبل",
  successfully_registered: "تم التسجيل بنجاح",
  verification_code_email: "تم ارسال رمز التحقق الي البريد",
  resend: "إعادة إرسال",
  resend_verification_code_after: "إعادة إرسال رمز التحقق بعد",
  verification_code_invalid: "كود التحقق غير صحيح",
  account_has_been_confirmed: "تم تأكيد الحساب بنجاح",
  change_password: "تغيير كلمة المرور",
  password_changed_successfully: "تم تغيير كلمة المرور بنجاح",
  user_information: "معلومات المستخدم",
  purchase_history: "سجل المشتريات",
  dashboard_user: "لوحة التحكم",
  save_data: "حفظ",
  updated_successfully: "تم التحديث بنجاح",
  date_of_registration: "تاريخ التسجيل",
  subscription: "اشتراك",
  successfully_payment: "تم الدفع بنجاح",
  error_payment: "حدث خطأ اثناء عملية الدفع",
  payment_Date: "تاريخ الدفع",
  payment_status: "حالة الدفع",
  successful_payment: "عملية دفع ناجحة",
  failed_payment: "عملية دفع فاشلة",
  payment_code: "كود الدفع",
  product_details: "تفاصيل المنتج",
  recipient_address: "عنوان المرسل اليه",
  whats_app: "واتساب",
  type_payment_method: "نوع وسيلة الدفع",
  question: "سؤال",
  answer: "اجابة",
  gender_user: "النوع",
  individual: "فرد",
  team: "فريق",
  male: "ذكر",
  feminine: "انثى",
  user_details: "تفاصيل المستخدم",
  products_details: "تفاصيل المنتجات",
  tournament_details: "تفاصيل البطولة",
  full_subscribers: "Full",
};

export default arabicDataAr;
