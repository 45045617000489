import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ViewData from "../../components/services/ViewData";
import Loading from "../../components/loading/Loading";
import "./championship.css";
import { useTranslation } from "react-i18next";
import { MdOutlineDateRange } from "react-icons/md";
import { toast } from "react-toastify";
import CompletePurchaseChampionship from "../../components/navbar/icon/CompletePurchaseChampionship";
const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

const Championship = () => {
  const { itemId } = useParams();
  const [viewChampionship, setViewChampionship] = useState([]);
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const { i18n, t } = useTranslation();
  const [isDisabled, setDisabled] = useState(false);
  const [openPage, setOpenPage] = useState(false);
  const trueRef = useRef(true);

  const dataChampionship = async () => {
    setLoading(true);
    try {
      const result = await ViewData(
        "championship/championship.php",
        itemId,
        null
      );
      setViewChampionship(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      dataChampionship();
      trueRef.current = false;
    }
  }, []);

  const handleSubscription = () => {
    setOpenPage(true);
  };

  const handleSoon = () => {
    setDisabled(true);

    setTimeout(() => {
      toast.success(t("coming_soon"));
      setDisabled(false);
    }, 2000);
  };

  let product = [
    {
      id: viewChampionship[0]?.id,
      name: viewChampionship[0]?.title_en,
      description: viewChampionship[0]?.title_en,
      price: parseInt(viewChampionship[0]?.price),
      quantity: 1,
    },
  ];

  // console.log(viewChampionship);

  return (
    <>
      {openPage && (
        <CompletePurchaseChampionship
          setOpenPage={setOpenPage}
          product={product}
          viewChampionship={viewChampionship}
        />
      )}

      <div className="pages">
        {error ? (
          "Error"
        ) : load ? (
          <Loading />
        ) : (
          <>
            {viewChampionship &&
              viewChampionship?.map((item, i) => (
                <div className="item_view_championship" key={i}>
                  <img
                    src={`${LinkLikeBachend}${item.img}`}
                    alt={item?.description_en}
                  />

                  <div className="fixed_information">
                    <small>
                      <MdOutlineDateRange /> {item?.date_add}
                    </small>

                    {item?.certainly_or_soon == "0" && (
                      <div className="soon">
                        <small>{t("coming_soon")}</small>
                      </div>
                    )}
                  </div>

                  {i18n.language == "en" ? (
                    <>
                      <h1>{item?.title_en}</h1>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item?.description_en,
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <h1>{item?.title_ar}</h1>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item?.description_ar,
                        }}
                      />
                    </>
                  )}
                </div>
              ))}

            {viewChampionship[0].number_subscribers == 0 && (
              <div className="subscription">
                <button
                  className="button"
                  disabled={true}
                  style={{ opacity: 0.3, background: "red" }}
                >
                  {t("full_subscribers")}
                </button>
              </div>
            )}

            {viewChampionship[0].number_subscribers > "0" && (
              <div className="subscription">
                {viewChampionship[0]?.certainly_or_soon === "0" ? (
                  <button
                    className="button"
                    onClick={handleSoon}
                    disabled={isDisabled}
                    style={{ opacity: isDisabled ? 0.3 : "" }}
                  >
                    {isDisabled ? t("waiting") : t("subscription")}
                  </button>
                ) : (
                  <button
                    className="button"
                    onClick={handleSubscription}
                    disabled={isDisabled}
                    style={{ opacity: isDisabled ? 0.3 : "" }}
                  >
                    {isDisabled ? t("waiting") : t("subscription")}
                  </button>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Championship;
