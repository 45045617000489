import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Fieldset from "../../components/input/Fieldset";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import CreateData from "../../components/services/CreateData";
import Text from "../../components/input/Text";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useAppContext } from "../../components/context/AppProvider";
import { NavLink, useNavigate } from "react-router-dom";
import OtpEmail from "./OtpEmail";
import PhoneInputN from "../../components/input/PhoneInputN";

const Register = () => {
  const methods = useForm();
  const { t } = useTranslation();
  const [typeText, setTypeText] = useState("password");
  const [isDisabled, setDisabled] = useState(false);
  const { user } = useAppContext();
  const navigate = useNavigate();
  const [otpEmailOpen, setOtpEmailOpen] = useState(false);
  const [tokinUser, setTokinUser] = useState("");

  useEffect(() => {
    if (user != null) {
      navigate("/user-dashboard", { replace: true });
    }
  }, [user]);

  const handleChangepPassword = () => {
    typeText == "password" ? setTypeText("text") : setTypeText("password");
  };

  const onSubmit = async (data, e) => {
    if (methods.watch("phone_number").length > 5) {
      setDisabled(true);

      try {
        const result = await CreateData("register_user.php", e);

        setDisabled(false);

        if (result.request == "email_already_registered") {
          toast.warning(t("email_already_registered"));
        } else if (result.request == "successfully") {
          toast.success(t("successfully_registered"));
          methods.reset();

          setTokinUser(result.token);
          setOtpEmailOpen(true);
        } else {
          toast.error(t("an_error_occurred"));
          console.log(result);
        }
      } catch (error) {
        toast.error(t("an_error_occurred"));
        setDisabled(false);
        console.log(error);
      }
    } else {
      toast.warning(t("phone_number_failed"));
    }
  };

  return (
    <>
      {otpEmailOpen && (
        <OtpEmail setOtpEmailOpen={setOtpEmailOpen} tokinUser={tokinUser} />
      )}

      <div className="pages">
        <div className="pages support">
          <div className="left_right">
            <div className="right">
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Fieldset
                    type="text"
                    name="full_name"
                    placeholder={t("full_name")}
                    required={true}
                  />

                  <PhoneInputN
                    name="phone_number"
                    placeholder={t("phone_number")}
                    required={true}
                  />

                  <Fieldset
                    type="email"
                    name="email_user"
                    placeholder={t("email")}
                    required={true}
                  />

                  <div className="password_eye">
                    <Text
                      type={typeText}
                      name="password_user"
                      placeholder={t("password")}
                      required={true}
                    />

                    {typeText == "password" ? (
                      <FaEye onClick={handleChangepPassword} />
                    ) : (
                      <FaEyeSlash onClick={handleChangepPassword} />
                    )}
                  </div>

                  <button
                    type="submit"
                    className="button"
                    disabled={isDisabled}
                    style={{ opacity: isDisabled ? 0.3 : "" }}
                  >
                    {isDisabled ? t("waiting") : t("submit")}
                  </button>

                  <NavLink to="/login">{t("login")}</NavLink>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
