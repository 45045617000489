import React, { useEffect, useRef, useState } from "react";
import "./support.css";
import { useTranslation } from "react-i18next";
import ViewData from "../../components/services/ViewData";
import Loading from "../../components/loading/Loading";
import { MdOutlineLocalPhone, MdOutlineMail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";

const Support = () => {
  const { t } = useTranslation();
  const [dataSupport, setDataSupport] = useState();
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const trueRef = useRef(true);

  const submitData = async () => {
    try {
      const result = await ViewData("support.php");
      setDataSupport(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      submitData();
      trueRef.current = false;
    }
  }, []);

  // console.log(dataSupport);

  return (
    <div className="pages">
      <div className="pages support">
        <div className="left_right">
          <div className="left">
            {error ? (
              "Error"
            ) : load ? (
              <Loading />
            ) : (
              <>
                <h1>{t("support_assistance")}</h1>
                {dataSupport && (
                  <>
                    <div className="support_div">
                      {dataSupport.phone_number_1 && (
                        <a
                          href={`tel:+${dataSupport.phone_number_1}`}
                          target="_blank"
                        >
                          <MdOutlineLocalPhone />{" "}
                          <span>{dataSupport.phone_number_1}</span>
                        </a>
                      )}

                      {dataSupport.phone_number_2 && (
                        <a
                          href={`tel:+${dataSupport.phone_number_2}`}
                          target="_blank"
                        >
                          <MdOutlineLocalPhone />{" "}
                          <span>{dataSupport.phone_number_2}</span>
                        </a>
                      )}
                    </div>

                    <div className="support_div">
                      {dataSupport.whats_app && (
                        <a href={dataSupport.whats_app} target="_blank">
                          <FaWhatsapp /> {t("whats_app")}
                        </a>
                      )}
                    </div>

                    <div className="support_div">
                      {dataSupport.email_1 && (
                        <a
                          href={`mailto:${dataSupport.email_1}`}
                          target="_blank"
                        >
                          <MdOutlineMail /> {dataSupport.email_1}
                        </a>
                      )}

                      {dataSupport.email_2 && (
                        <a
                          href={`mailto:${dataSupport.email_2}`}
                          target="_blank"
                        >
                          <MdOutlineMail /> {dataSupport.email_2}
                        </a>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
