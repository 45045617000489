import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import ViewData from "../../../components/services/ViewData";
import UpdateData from "../../../components/services/UpdateData";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Fieldset from "../../../components/input/Fieldset";
import PhoneInputN from "../../../components/input/PhoneInputN";
import Text from "../../../components/input/Text";
import Loading from "../../../components/loading/Loading";

const ViewCustomers = () => {
  const { idCustomer } = useParams();
  const methods = useForm();
  const [viewCustomer, setviewCustomer] = useState([]);
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const trueRef = useRef(true);

  const dataCustomer = async () => {
    setLoading(true);

    try {
      const result = await ViewData("customers/customers.php", idCustomer);
      setviewCustomer(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const [typeText, setTypeText] = useState("password");
  const [isDisabled, setDisabled] = useState(false);

  const handleChangepPassword = () => {
    typeText == "password" ? setTypeText("text") : setTypeText("password");
  };

  const onSubmit = async (data, e) => {
    if (methods.watch("phone_number").length > 5) {
      setDisabled(true);

      try {
        const result = await UpdateData("update_user_information.php", e);

        setDisabled(false);

        if (result.request == "successfully") {
          toast.success("updated successfully");
          methods.setValue("password_user", "");
        } else {
          toast.error("an error occurred");
          console.log(result);
        }
      } catch (error) {
        toast.error("an error occurred");
        setDisabled(false);
        console.log(error);
      }
    } else {
      toast.warning("phone number failed");
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      dataCustomer();
      trueRef.current = false;
    }
  }, []);

  console.log(viewCustomer);

  return (
    <>
      {error ? (
        "Error"
      ) : load ? (
        <Loading />
      ) : (
        <div className="pages">
          <div className="pages support">
            <div className="left_right">
              <div className="right">
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Fieldset
                      type="text"
                      name="full_name"
                      placeholder="full name"
                      required={true}
                      value={viewCustomer[0]?.full_name}
                    />

                    <PhoneInputN
                      name="phone_number"
                      placeholder="phone number"
                      required={true}
                      value={viewCustomer[0]?.phone_number}
                    />

                    <Fieldset
                      type="email"
                      name="email_user"
                      placeholder="email"
                      required={true}
                      readOnly={true}
                      value={viewCustomer[0]?.email_user}
                    />
                    <div className="date_of_registration">
                      <small>date of registration</small>
                      <small>{viewCustomer[0]?.date_add}</small>
                    </div>

                    <div className="password_eye">
                      <Text
                        type={typeText}
                        name="password_user"
                        placeholder="change password"
                        required={false}
                      />

                      {typeText == "password" ? (
                        <FaEye onClick={handleChangepPassword} />
                      ) : (
                        <FaEyeSlash onClick={handleChangepPassword} />
                      )}
                    </div>
                    <button
                      type="submit"
                      className="button"
                      disabled={isDisabled}
                      style={{ opacity: isDisabled ? 0.3 : "" }}
                    >
                      {isDisabled ? "waiting" : "save data"}
                    </button>
                  </form>
                </FormProvider>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewCustomers;
