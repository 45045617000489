import React, { createContext, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ViewData from "../services/ViewData";
import { useNavigate } from "react-router-dom";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [direction, setDirection] = useState("ltr");
  const [countBasket, setCountBasket] = useState(0);
  const { t } = useTranslation();

  let cookiesLoginAdmin = Cookies.get("login_admin")
    ? Cookies.get("login_admin")
    : null;

  let cookiesLoginUser = Cookies.get("login_user")
    ? Cookies.get("login_user")
    : null;

  const [admin, setAdmin] = useState(cookiesLoginAdmin);
  const [user, setUser] = useState(cookiesLoginUser);
  const [dataUser, setdataUser] = useState([]);
  const [basketProducts, setBasketProducts] = useState([]);
  const navigate = useNavigate();

  const loginAdmin = (newtoken) => {
    Cookies.set("login_admin", newtoken, { expires: 1 });

    setAdmin(Cookies.get("login_admin"));
  };

  const logOutAdmin = () => {
    Cookies.remove("login_admin");

    window.location.reload();
  };

  const handelDataAdmin = async (newtoken) => {
    try {
      const response = await ViewData("verification_admin.php", newtoken);

      if (response.request != "successfully") {
        setAdmin(null);
        Cookies.remove("login_admin");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handelDataUser = async (newtoken) => {
    try {
      const response = await ViewData("verification_user.php", newtoken);

      if (response.request == "error") {
        setUser(null);
        Cookies.remove("login_user");
      } else {
        setdataUser(response);
        setUser(response.tokin_user);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const loginUser = async (newtoken) => {
    try {
      const response = await ViewData("verification_user.php", newtoken);

      if (response.request == "error") {
        setUser(null);
        Cookies.remove("login_user");
      } else {
        Cookies.set("login_user", newtoken, { expires: 1 });
        setdataUser(response);
        setUser(response.tokin_user);

        if (sessionStorage.getItem("urlChampionship")) {
          navigate(sessionStorage.getItem("urlChampionship"), {
            replace: true,
          });

          sessionStorage.removeItem("urlChampionship");
        } else {
          navigate("/user-dashboard", { replace: true });
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const logOutUser = () => {
    Cookies.remove("login_user");
    setdataUser([]);

    window.location.reload();
  };

  const handleAddToBasket = (itemId, store) => {
    let basket = [];

    if (localStorage.getItem("basket")) {
      basket = JSON.parse(localStorage.getItem("basket"));
    }

    const item = store.find((item) => item.id === itemId);

    if (item) {
      if (!basket.some((basketItem) => basketItem.id === item.id)) {
        item.count = 1;
        basket.push(item);
      } else {
        toast.warning(t("already_inside_cart"));
      }
    } else {
      toast.warning(t("an_error_occurred"));
    }

    localStorage.setItem("basket", JSON.stringify(basket));

    if (localStorage.getItem("basket")) {
      setCountBasket(JSON.parse(localStorage.getItem("basket")).length);
    }

    setBasketProducts(basket);
  };

  const removeFromBasket = (itemId) => {
    let basket = [];

    if (localStorage.getItem("basket")) {
      basket = JSON.parse(localStorage.getItem("basket"));
    }

    basket = basket.filter((item) => item.id !== itemId);

    localStorage.setItem("basket", JSON.stringify(basket));

    setCountBasket(basket.length);

    setBasketProducts(basket);
  };

  const updateItemCount = (itemId, increment) => {
    let basket = [];

    if (localStorage.getItem("basket")) {
      basket = JSON.parse(localStorage.getItem("basket"));
    }

    const index = basket.findIndex((item) => item.id === itemId);

    if (index !== -1) {
      basket[index].count += increment;

      if (basket[index].count < 1) {
        basket[index].count = 1;
      }

      localStorage.setItem("basket", JSON.stringify(basket));
    }

    setBasketProducts(basket);
  };

  const privateRoute = () => {
    return user == null && navigate("/login", { replace: true });
  };

  useEffect(() => {
    if (cookiesLoginAdmin) {
      handelDataAdmin(cookiesLoginAdmin);
    }
    if (cookiesLoginUser) {
      handelDataUser(cookiesLoginUser);
    }

    if (localStorage.getItem("basket")) {
      setCountBasket(JSON.parse(localStorage.getItem("basket")).length);

      setBasketProducts(JSON.parse(localStorage.getItem("basket")));
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        admin,
        dataUser,
        loginAdmin,
        logOutAdmin,
        user,
        loginUser,
        logOutUser,
        direction,
        setDirection,
        countBasket,
        handleAddToBasket,
        removeFromBasket,
        updateItemCount,
        basketProducts,
        privateRoute,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
