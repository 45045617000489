import axios from "axios";
const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
const validation = process.env.REACT_APP_VALIDATION;

export const HandelUpdateOrder = async (trackId, payment_status) => {
  const formData = new FormData();
  formData.append("validation", validation);
  formData.append("trackId", trackId);
  formData.append("payment_status", payment_status);

  try {
    const response = await axios.post(
      `${LinkLikeBachend}update/order.php`,
      formData
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
