import axios from "axios";
const mainWebsiteUrl = window.location.origin;
const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
const AuthorizationPayment = process.env.REACT_APP_AUTHORIZATION_PAYMENT;

let totalAmount = 0;

const HandelPayment = async (
  order_type,
  product,
  dataUserinfo,
  urlPage,
  paymentMethod,
  order_reference
) => {
  product.forEach((item) => {
    const price = parseInt(item.price);
    const quantity = parseInt(item.quantity);

    totalAmount += price * quantity;
  });

  const options = {
    method: "POST",
    url: "https://uapi.upayments.com/api/v1/charge",
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      Authorization: `Bearer ${AuthorizationPayment}`,
    },
    data: {
      products: product,
      order: {
        id: order_reference,
        reference: order_reference,
        description: order_type,
        currency: "KWD",
        amount: totalAmount,
      },
      language: "en",
      reference: {
        id: order_reference,
      },
      customer: dataUserinfo,
      returnUrl: `${mainWebsiteUrl}/successful-payment`,
      cancelUrl: `${mainWebsiteUrl}/error-payment`,
      notificationUrl:
        "https://webhook.site/d7c6e1c8-b98b-4f77-8b51-b487540df336",
      paymentGateway: {
        src: paymentMethod,
      },
    },
  };

  try {
    const response = await axios.request(options);
    const responseDB = await axios.post(`${LinkLikeBachend}create/${urlPage}`, {
      requestData: options.data,
      trackId: order_reference,
      userId: options.data.customer.userId,
    });

    totalAmount = 0;
    if (responseDB.data.request === "successfully") {
      return response.data;
    } else {
      return responseDB.data;
    }
  } catch (error) {
    throw error;
  }
};

export default HandelPayment;
