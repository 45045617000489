import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import JoditEditorInput from "../../../components/input/JoditEditorInput";
import ViewData from "../../../components/services/ViewData";
import Loading from "../../../components/loading/Loading";
import UpdateData from "../../../components/services/UpdateData";

const AdminAboutUs = () => {
  const [isDisabled, setDisabled] = useState(false);
  const methods = useForm();
  const [dataPage, setDataPage] = useState();
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const trueRef = useRef(true);

  const submitData = async () => {
    setLoading(true);

    try {
      const result = await ViewData("about_us.php");
      setDataPage(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      submitData();
      trueRef.current = false;
    }
  }, []);

  const [editorConfigEN] = useState({
    placeholder: "",
    direction: "ltr",
    minHeight: "500",
  });

  const [editorConfigAR] = useState({
    placeholder: "",
    direction: "rtl",
    minHeight: "500",
  });

  const onSubmit = async (data, e) => {
    setDisabled(true);

    try {
      const result = await UpdateData("about_us.php", e);

      setDisabled(false);

      if (result.request == "successfully") {
        setDisabled(false);

        toast.success("Updated successfully");
        submitData();
      } else {
        toast.warning("an error occurred");

        setDisabled(false);
        console.log(result);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("an error occurred");

      setDisabled(false);
    }
  };

  // console.log(dataPage);

  return (
    <div className="admin_page">
      <h1>AboutUs</h1>

      {error ? (
        "Error"
      ) : load ? (
        <Loading />
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <fieldset>
              <legend>AboutUs EN</legend>

              <JoditEditorInput
                name="text_en"
                editorConfig={editorConfigEN}
                value={dataPage?.text_en}
              />
            </fieldset>

            <fieldset>
              <legend>AboutUs AR</legend>

              <JoditEditorInput
                name="text_ar"
                editorConfig={editorConfigAR}
                value={dataPage?.text_ar}
              />
            </fieldset>
            <button
              type="submit"
              className="button"
              disabled={isDisabled}
              style={{ opacity: isDisabled ? 0.3 : "" }}
            >
              {isDisabled ? "waiting..." : "submit"}
            </button>
          </form>
        </FormProvider>
      )}
    </div>
  );
};

export default AdminAboutUs;
