import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoMdCloseCircle } from "react-icons/io";
import { useAppContext } from "../../context/AppProvider";
import CompletePurchase from "./CompletePurchase";
const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

const Basket = ({ setOpenBasket }) => {
  const { removeFromBasket, updateItemCount, basketProducts } = useAppContext();
  const { i18n, t } = useTranslation();
  let totalBasket;
  const [openPage, setOpenPage] = useState(false);

  const handleBasket = () => {
    setOpenBasket(false);
  };

  let product = basketProducts?.map((item) => ({
    id: parseInt(item.id),
    name: item.title_en,
    description: item.title_en,
    price: parseInt(item.price),
    quantity: parseInt(item.count),
  }));

  const handleOpenPay = () => {
    setOpenPage(true);
  };

  // console.log(basketProducts);

  // console.log("******************");

  // console.log(product);

  return (
    <>
      {openPage && (
        <CompletePurchase
          setOpenPage={setOpenPage}
          product={product}
          setOpenBasket={setOpenBasket}
        />
      )}

      <div className="full_basket">
        <div className="basket">
          <IoMdCloseCircle onClick={handleBasket} />
          <ul>
            {basketProducts.length > 0 ? (
              basketProducts?.map((item, i) => {
                let totalItemBasket = item.price * item.count;

                totalBasket = basketProducts.reduce((total, item) => {
                  const totalItemBasket = item.price * item.count;
                  return total + totalItemBasket;
                }, 0);

                return (
                  <li key={i}>
                    <IoMdCloseCircle
                      className="remove_from_basket"
                      onClick={() => removeFromBasket(item.id)}
                    />

                    <div className="top">
                      <img
                        src={`${LinkLikeBachend}${item.img}`}
                        alt={item.text_en}
                      />
                      <p>
                        {i18n.language == "en" ? item.title_en : item.title_ar}
                      </p>
                    </div>
                    <div className="botton">
                      <p className="currency_price">
                        <span className="total_price">{totalItemBasket}</span>{" "}
                        {t("currency")}
                      </p>

                      <div className="count_basket">
                        <button
                          className="button button_1"
                          onClick={() => updateItemCount(item.id, 1)}
                        >
                          +
                        </button>
                        <p className="item_count_basket">{item.count}</p>
                        <button
                          className="button button_2"
                          onClick={() => updateItemCount(item.id, -1)}
                        >
                          -
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <div className="basket_empty">
                <b>{t("basket_empty")}</b>
              </div>
            )}
          </ul>

          {basketProducts.length > 0 && (
            <div className="total_pay">
              <div className="total">
                <p>
                  <span className="total_price">{totalBasket}</span>{" "}
                  {t("currency")}
                </p>
                <p>{t("total")}</p>
              </div>

              <button className="button" onClick={handleOpenPay}>
                {t("pay")}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Basket;
