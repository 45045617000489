import React, { useEffect, useRef, useState } from "react";
import { useAppContext } from "../../../components/context/AppProvider";
import { useTranslation } from "react-i18next";
import ViewDataCategory from "../../../components/services/ViewDataCategory";
import Loading from "../../../components/loading/Loading";
import all_category from "../../../img/all_category.webp";
import { NavLink } from "react-router-dom";

const PurchaseHistory = () => {
  const context = useAppContext();
  const [orders, setOrders] = useState([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [isDisabledLoadMore, setDisabledLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentType, setPayment_type] = useState("0");
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const { t } = useTranslation();
  const trueRef = useRef(true);

  const dataOrders = async () => {
    setLoading(true);
    setCurrentPage(1);

    try {
      const result = await ViewDataCategory(
        "orders/order.php",
        context.dataUser.id,
        null,
        paymentType
      );
      setOrders(result.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataOrdersCount = async () => {
    try {
      const result = await ViewDataCategory(
        "orders/order_count.php",
        context.dataUser.id,
        null,
        paymentType
      );

      setOrdersCount(result.request);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataOrdersLoadMore = async () => {
    setDisabledLoadMore(true);
    try {
      const result = await ViewDataCategory(
        "orders/order.php",
        context.dataUser.id,
        currentPage + 1,
        paymentType
      );

      dataOrdersCount();

      setTimeout(() => {
        setOrders((prevData) => prevData.concat(result.data));

        setDisabledLoadMore(false);

        setCurrentPage((prevPage) => prevPage + 1);
        setLoading(false);
      }, 2000);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      context.privateRoute();
      dataOrders();
      dataOrdersCount();
      trueRef.current = false;
    }
  }, [paymentType, context.dataUser.id]);

  // console.log(orders);
  // console.log(ordersCount);

  return (
    <div className="pages">
      {error ? (
        "Error"
      ) : load ? (
        <Loading />
      ) : (
        <div className="full_championship">
          <h2>{t("purchase_history")}</h2>
          <div className="categories">
            <button
              className={`category ${
                paymentType === "0" ? "active_category" : ""
              } `}
              onClick={() => {
                setPayment_type("0");
                dataOrders();
              }}
              disabled={isDisabledLoadMore}
              style={{ opacity: isDisabledLoadMore ? 0.3 : "" }}
            >
              <img src={all_category} alt="all_category" />
              {t("home")}
            </button>
            <button
              className={`category ${
                paymentType === "1" ? "active_category" : ""
              } `}
              onClick={() => {
                setPayment_type("1");
                dataOrders();
              }}
              disabled={isDisabledLoadMore}
              style={{ opacity: isDisabledLoadMore ? 0.3 : "" }}
            >
              <img src={all_category} alt="all_category" />
              {t("store")}
            </button>
          </div>
          <div className="orders">
            {orders && orders.length > 0
              ? orders?.map((item, i) => {
                  const infoC = item.requestData.customer;

                  return (
                    <div className="order" key={i}>
                      <>
                        {item.payment_status === "1" ? (
                          <div className="successful_payment">
                            <small>
                              {t("payment_status")}: {t("successful_payment")}
                            </small>
                          </div>
                        ) : (
                          <div className="failed_payment">
                            <small>
                              {t("payment_status")}: {t("failed_payment")}
                            </small>
                          </div>
                        )}
                      </>

                      <div className="all_product">
                        <div className="product">
                          <small>
                            {t("payment_Date")}: {item.date_add}
                          </small>

                          <p>
                            {t("payment_code")}:{" "}
                            <span>{item.requestData.reference.id}</span>
                          </p>

                          <p>
                            {t("total")}: {item.requestData.order.amount}{" "}
                            {t("currency")}
                          </p>
                        </div>

                        {/* in cliente */}
                        <div className="product">
                          <b>{t("user_details")}</b>

                          <p>
                            {t("full_name")}: <span>{infoC.name}</span>
                          </p>
                          <p>
                            {t("email")}: <span>{infoC.email}</span>
                          </p>
                          <p>
                            {t("phone_number")}: <span>{infoC.mobile}</span>
                          </p>

                          {item.payment_type === "0" && (
                            <>
                              <p>
                                {t("gender_user")}:{" "}
                                <span>{infoC.gender_user}</span>
                              </p>
                              <p>
                                <span>{infoC.team_or_individual_user}</span>
                              </p>
                            </>
                          )}

                          {item.payment_type === "1" && (
                            <>
                              <p>
                                {t("recipient_address")}:{" "}
                                <span>{infoC.address}</span>
                              </p>
                              <p>
                                size: <span>{infoC.size}</span>
                              </p>
                            </>
                          )}
                        </div>

                        {/* in answer */}

                        {item.payment_type === "0" && (
                          <div className="product">
                            {item.answerChampion.length > 0 &&
                              item.answerChampion.map((answer, i) => (
                                <p key={i}>
                                  {answer.question}
                                  <span>{answer.answer}</span>
                                </p>
                              ))}
                          </div>
                        )}

                        {/* in products */}
                        <div className="product">
                          {item.payment_type === "1"
                            ? t("products_details")
                            : ""}
                          {item.requestData.products?.map((product, index) => (
                            <div key={index} className="product">
                              {item.payment_type === "0" ? (
                                <NavLink
                                  className="button"
                                  to={`/championship/${product.id}`}
                                >
                                  {t("tournament_details")}
                                </NavLink>
                              ) : (
                                item.payment_type === "1" && (
                                  <NavLink
                                    className="button"
                                    to={`/store/${product.id}`}
                                  >
                                    {t("product_details")}
                                  </NavLink>
                                )
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  );
                })
              : t("no_data")}
          </div>

          <div className="div_load_more">
            {orders &&
              orders?.length > 0 &&
              parseInt(ordersCount) !== orders?.length && (
                <button
                  className="button"
                  onClick={dataOrdersLoadMore}
                  disabled={isDisabledLoadMore}
                  style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
                >
                  {!isDisabledLoadMore ? t("more") : t("loading")}
                </button>
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PurchaseHistory;
