import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ViewData from "../../../components/services/ViewData";
import Loading from "../../../components/loading/Loading";
import "../store.css";
import { useTranslation } from "react-i18next";
import { IoMdPricetags } from "react-icons/io";
import { BsFillBasket3Fill } from "react-icons/bs";
import { useAppContext } from "../../../components/context/AppProvider";

const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

const ViewStore = () => {
  const { itemId } = useParams();
  const [viewStore, setViewStore] = useState([]);
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const { i18n, t } = useTranslation();
  const { handleAddToBasket } = useAppContext();
  const trueRef = useRef(true);

  const dataStore = async () => {
    setLoading(true);
    try {
      const result = await ViewData("store/store.php", itemId, null);
      setViewStore(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      dataStore();
      trueRef.current = false;
    }
  }, []);

  // console.log(viewStore);

  return (
    <div className="pages">
      {error ? (
        "Error"
      ) : load ? (
        <Loading />
      ) : (
        <>
          {viewStore &&
            viewStore?.map((item, i) => (
              <div className="item_view_store" key={i}>
                <img
                  src={`${LinkLikeBachend}${item.img}`}
                  alt={item?.description_en}
                />

                <div className="fixed_information">
                  {item?.price > 0 && (
                    <small>
                      <IoMdPricetags /> {item?.price} {t("currency")}
                    </small>
                  )}
                </div>

                {i18n.language == "en" ? (
                  <>
                    <h1>{item?.title_en}</h1>
                    <div
                      dangerouslySetInnerHTML={{ __html: item?.description_en }}
                    />
                  </>
                ) : (
                  <>
                    <h1>{item?.title_ar}</h1>
                    <div
                      dangerouslySetInnerHTML={{ __html: item?.description_ar }}
                    />
                  </>
                )}

                <div className="add_basket">
                  <button
                    className="button add_basket"
                    onClick={() => handleAddToBasket(item.id, viewStore)}
                  >
                    {t("add_basket")}
                    <BsFillBasket3Fill />
                  </button>
                </div>
              </div>
            ))}
        </>
      )}
    </div>
  );
};

export default ViewStore;
