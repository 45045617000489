import React, { useEffect, useRef, useState } from "react";
import "./footer.css";
import { NavLink } from "react-router-dom";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { useTranslation } from "react-i18next";
import SocialMediaLinks from "../socialMediaLinks/SocialMediaLinks";
import ViewData from "../services/ViewData";
import Loading from "../loading/Loading";

const Footer = () => {
  const { i18n, t } = useTranslation();
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const [viewSocialMedia, setSocialMedia] = useState([]);
  const [viewSettings, setSettings] = useState({});
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const trueRef = useRef(true);

  const dataSocialMedia = async () => {
    try {
      const result = await ViewData("social_media.php");
      setSocialMedia(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataSettings = async () => {
    try {
      const result = await ViewData("settings.php");
      setSettings(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      dataSocialMedia();
      dataSettings();
      trueRef.current = false;
    }
  }, []);

  // console.log(viewSocialMedia);

  return (
    <div id="footer">
      {error ? (
        "Error"
      ) : load ? (
        <Loading />
      ) : (
        <>
          <div>
            <NavLink to="/" className="logo">
              <img src={`${LinkLikeBachend}${viewSettings?.logo}`} alt="logo" />
            </NavLink>
          </div>
          <div className="center">
            <p>
              {i18n.language == "en"
                ? viewSettings?.copyright_en
                : viewSettings?.copyright_ar}
            </p>

            <SocialMediaLinks viewSocialMedia={viewSocialMedia} />
          </div>
        </>
      )}

      <div>
        <NavLink to="/privacy-policy">
          <MdOutlinePrivacyTip /> {t("privacy_policy")}
        </NavLink>
      </div>
    </div>
  );
};

export default Footer;
