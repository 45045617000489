import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "./adminSlider.css";
import background_upload from "../../../img/background_upload.webp";
import Img from "../../../components/input/Img";
import { IoMdCloseCircle } from "react-icons/io";
import ViewData from "../../../components/services/ViewData";
import Loading from "../../../components/loading/Loading";
import DeleteData from "../../../components/services/DeleteData";
import CreateData from "../../../components/services/CreateData";

const AdminSlider = () => {
  const [isDisabled, setDisabled] = useState(false);
  const methods = useForm();
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const [isBackgroundImg, setBackgroundImg] = useState("");
  const [viewAdminSlider, setAdminSlider] = useState([]);
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const trueRef = useRef(true);

  const handleFileChange = (e) => {
    setBackgroundImg(URL.createObjectURL(e.target.files[0]));
  };

  const onSubmit = async (data, e) => {
    setDisabled(true);

    try {
      const result = await CreateData("slider.php", e);

      setDisabled(false);

      if (result.request == "successfully") {
        setDisabled(false);

        toast.success("Added successfully");

        methods.reset();
        setBackgroundImg("");
        dataAdminSlider();
      } else {
        toast.warning("An problem occurred");

        setDisabled(false);
        console.log(result);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An problem occurred");

      setDisabled(false);
    }
  };

  const dataAdminSlider = async () => {
    setLoading(true);
    try {
      const result = await ViewData("slider.php");
      setAdminSlider(result);
      setLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  const handleDelete = async (itemId) => {
    try {
      const result = await DeleteData("slider.php", itemId);

      if (result.request == "successfully") {
        toast.success("Deleted successfully");
        dataAdminSlider();
      } else {
        toast.error("An error occurred");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      dataAdminSlider();
      trueRef.current = false;
    }
  }, []);

  return (
    <div className="form admin_slider">
      <h1>Home Slider</h1>

      <div className="box">
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            encType="multipart/form-data"
          >
            <div>
              <label htmlFor="Size">Size: 1280px width by 400px height</label>
              <div className="div_background_upload">
                <Img
                  name="img"
                  type="file"
                  accept="image/*"
                  required={true}
                  change={handleFileChange}
                />
                <img
                  src={background_upload}
                  alt="background_upload"
                  className="background_img"
                />
              </div>

              {isBackgroundImg != "" && (
                <div className="background_upload">
                  <img src={isBackgroundImg} alt="background_upload" />
                </div>
              )}
            </div>

            <input
              type="submit"
              style={{ opacity: isDisabled ? 0.3 : 1, background: "var(--bl)" }}
              disabled={isDisabled}
              value="Add"
              className="button"
            />
          </form>
        </FormProvider>
      </div>

      {error ? (
        "Error"
      ) : load ? (
        <Loading />
      ) : (
        <div className="slider_div">
          {viewAdminSlider &&
            viewAdminSlider.length > 0 &&
            viewAdminSlider?.map((item, i) => (
              <div className="slider_item" key={i}>
                <div className="div_slider_item">
                  <img
                    src={`${LinkLikeBachend}${item.img}`}
                    alt={`slider_${i}`}
                  />
                </div>
                <IoMdCloseCircle
                  onClick={() => handleDelete(item.id)}
                  className="delete"
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default AdminSlider;
