import React, { useEffect, useRef, useState } from "react";
import "./privacyPolicy.css";
import { useTranslation } from "react-i18next";
import Loading from "../../components/loading/Loading";
import ViewData from "../../components/services/ViewData";

const PrivacyPolicy = () => {
  const { i18n, t } = useTranslation();
  const [dataPage, setDataPage] = useState();
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const trueRef = useRef(true);

  const submitData = async () => {
    try {
      const result = await ViewData("privacy_policy.php");
      setDataPage(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      submitData();
      trueRef.current = false;
    }
  }, []);

  // console.log(dataPage);

  return (
    <div className="pages">
      <div className="privacyPolicy">
        <h1>{t("privacy_policy")}</h1>

        {error ? (
          "Error"
        ) : load ? (
          <Loading />
        ) : (
          <>
            {i18n.language == "en" ? (
              <div
                className="dataPage"
                dangerouslySetInnerHTML={{ __html: dataPage?.text_en }}
              />
            ) : (
              <div
                className="dataPage"
                dangerouslySetInnerHTML={{ __html: dataPage?.text_ar }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
