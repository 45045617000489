import React, { useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import HandelPayment from "./../../handelPayment/HandelPayment";
import { useAppContext } from "../../context/AppProvider";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import cc from "../../../img/cc.png";
import knet from "../../../img/knet.png";
import apple from "../../../img/apple.png";
import { v4 as uuidv4 } from "uuid";

const CompletePurchase = ({ product, setOpenPage, setOpenBasket }) => {
  const [isDisabled, setDisabled] = useState(false);
  const { user, dataUser, direction } = useAppContext();
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState({
    nameUser: "",
    emailUser: "",
    mobileUser: "",
    addressUser: "",
    size: "",
  });

  const [paymentMethod, setPaymentMethod] = useState("");

  let order_reference = uuidv4();

  if (order_reference.length > 35) {
    order_reference = order_reference.replace(/-/g, "");
    order_reference = order_reference.substring(0, 35);
  }

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValue((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handlePhoneChange = (value, country, e, formattedValue) => {
    setInputValue((prevValue) => ({
      ...prevValue,
      mobileUser: value,
    }));
  };

  const handleClose = () => {
    setOpenPage(false);
    setOpenBasket(false);
  };

  const dataUserinfo = {
    uniqueId: order_reference,
    userId: user !== null ? dataUser.id : "",
    name: user !== null ? dataUser.full_name : inputValue.nameUser,
    email: user !== null ? dataUser.email_user : inputValue.emailUser,
    mobile: user !== null ? dataUser.phone_number : inputValue.mobileUser,
    address: inputValue.addressUser,
    size: inputValue.size,
  };

  // console.log(dataUserinfo);

  const handlePay = async (e) => {
    e.preventDefault();
    if (dataUserinfo.mobile.length > 5) {
      setDisabled(true);

      try {
        const result = await HandelPayment(
          "order product",
          product,
          dataUserinfo,
          "order_product.php",
          paymentMethod,
          order_reference
        );

        if (result.message.includes("successfully")) {
          window.location.href = result.data.link;
          localStorage.removeItem("basket");
        } else {
          toast.error(t("error_payment"));
          console.log(result);
          setDisabled(false);
        }
      } catch (error) {
        toast.error(t("error_payment"));
        console.log(error);
        setDisabled(false);
      }
    } else {
      toast.warning(t("phone_number_failed"));
    }
  };

  // console.log(paymentMethod);

  return (
    <div className="position_fixed payment_pay payment_pay_product">
      <IoMdCloseCircle onClick={handleClose} className="handleClose" />
      <div className="overflow_pages">
        <div className="left_right">
          <div className="right" style={{ direction: direction }}>
            <form onSubmit={handlePay} method="post">
              {user === null && (
                <>
                  <fieldset>
                    <legend>{t("full_name")}</legend>

                    <input
                      type="text"
                      value={inputValue.nameUser}
                      onChange={handleChange}
                      placeholder={t("full_name")}
                      required
                      className="input"
                      name="nameUser"
                    />
                  </fieldset>

                  <fieldset>
                    <legend>{t("email")}</legend>

                    <input
                      type="email"
                      value={inputValue.emailUser}
                      onChange={handleChange}
                      placeholder={t("email")}
                      required
                      className="input"
                      name="emailUser"
                    />
                  </fieldset>

                  <fieldset>
                    <legend>{t("phone_number")}</legend>

                    <PhoneInput
                      placeholder=""
                      country={"kw"}
                      className="input"
                      value={inputValue.mobileUser}
                      onChange={handlePhoneChange}
                      inputProps={{
                        required: true,
                        name: "mobileUser",
                      }}
                    />
                  </fieldset>
                </>
              )}
              <fieldset>
                <legend>{t("recipient_address")}</legend>

                <input
                  type="text"
                  value={inputValue.addressUser}
                  onChange={handleChange}
                  placeholder={t("recipient_address")}
                  required
                  className="input"
                  name="addressUser"
                />
              </fieldset>

              <fieldset>
                <legend>{t("type_payment_method")}</legend>

                <div className="payment_method size">
                  <label
                    htmlFor="size_1"
                    className={inputValue.size === "S" ? "active_checked" : ""}
                  >
                    S
                    <input
                      type="radio"
                      value="S"
                      onChange={handleChange}
                      name="size"
                      id="size_1"
                      required
                    />
                  </label>

                  <label
                    htmlFor="size_2"
                    className={inputValue.size === "M" ? "active_checked" : ""}
                  >
                    M
                    <input
                      type="radio"
                      value="M"
                      onChange={handleChange}
                      name="size"
                      id="size_2"
                    />
                  </label>

                  <label
                    htmlFor="size_3"
                    className={inputValue.size === "L" ? "active_checked" : ""}
                  >
                    L
                    <input
                      type="radio"
                      value="L"
                      onChange={handleChange}
                      name="size"
                      id="size_3"
                    />
                  </label>
                  <label
                    htmlFor="size_4"
                    className={inputValue.size === "XL" ? "active_checked" : ""}
                  >
                    XL
                    <input
                      type="radio"
                      value="XL"
                      onChange={handleChange}
                      name="size"
                      id="size_4"
                    />
                  </label>
                  <label
                    htmlFor="size_5"
                    className={
                      inputValue.size === "XXL" ? "active_checked" : ""
                    }
                  >
                    XXL
                    <input
                      type="radio"
                      value="XXL"
                      onChange={handleChange}
                      name="size"
                      id="size_5"
                    />
                  </label>
                </div>
              </fieldset>

              <fieldset>
                <legend>{t("type_payment_method")}</legend>

                <div className="payment_method">
                  <label
                    htmlFor="payment_method_1"
                    className={paymentMethod === "cc" ? "active_checked" : ""}
                  >
                    <img src={cc} alt="cc" />
                    <input
                      type="radio"
                      value="cc"
                      onChange={handlePaymentMethodChange}
                      name="payment_method"
                      id="payment_method_1"
                      required
                    />
                  </label>

                  <label
                    htmlFor="payment_method_2"
                    className={paymentMethod === "knet" ? "active_checked" : ""}
                  >
                    <img src={knet} alt="knet" />
                    <input
                      type="radio"
                      value="knet"
                      onChange={handlePaymentMethodChange}
                      name="payment_method"
                      id="payment_method_2"
                    />
                  </label>
                  <label
                    htmlFor="payment_method_3"
                    className={
                      paymentMethod === "apple-pay" ? "active_checked" : ""
                    }
                  >
                    <img src={apple} alt="apple" />
                    <input
                      type="radio"
                      value="apple-pay"
                      onChange={handlePaymentMethodChange}
                      name="payment_method"
                      id="payment_method_3"
                    />
                  </label>
                </div>
              </fieldset>

              <button
                type="submit"
                className="button"
                disabled={isDisabled}
                style={{ opacity: isDisabled ? 0.3 : "" }}
              >
                {isDisabled ? t("waiting") : t("pay")}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletePurchase;
