import React, { useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";

const Fieldset = ({
  name,
  type,
  readOnly,
  placeholder,
  required,
  className,
  value,
}) => {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    if (value) {
      setValue(name, value);
    }
  }, [value]);

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <fieldset>
            <legend>{placeholder}</legend>

            <input
              {...field}
              type={type}
              readOnly={readOnly}
              required={required}
              placeholder={placeholder}
              className={className}
            />
          </fieldset>
        )}
      />
    </>
  );
};

export default Fieldset;
