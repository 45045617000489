import React, { useEffect, useRef, useState } from "react";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ViewData from "../../components/services/ViewData";
import Timer from "./../../components/timer/Timer";
import { useNavigate } from "react-router-dom";

const OtpEmail = ({ setOtpEmailOpen, tokinUser }) => {
  const [isDisabled, setDisabled] = useState(false);
  const ref = useRef(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [otp, setOtp] = useState("");
  const [resend, setResend] = useState(false);
  const [time, setTime] = useState(60);

  const handleSendTokinUser = async () => {
    setDisabled(true);

    try {
      const result = await ViewData("send_email_otp.php", tokinUser);

      setDisabled(false);
      if (result == "successfully") {
        toast.success(t("verification_code_email"));
        setResend(false);
      } else if (result == "user_incorrect") {
        toast.warning(t("user_not_registered"));
      } else {
        toast.error(t("an_error_occurred"));
      }
    } catch (error) {
      toast.error(t("an_error_occurred"));
      setDisabled(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (ref.current == true) {
      ref.current = false;
      handleSendTokinUser();
    }
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);

    try {
      const result = await ViewData(
        "verification_code_email.php",
        tokinUser,
        otp
      );

      setDisabled(false);

      if (result.request == "successfully") {
        toast.success(t("account_has_been_confirmed"));
        setOtpEmailOpen(false);
        navigate("/login");
      } else if (result.request == "verification_code_invalid") {
        toast.warning(t("verification_code_invalid"));
      } else if (result.request == "user_incorrect") {
        toast.warning(t("user_not_registered"));
      } else {
        toast.error(t("an_error_occurred"));
      }
    } catch (error) {
      toast.error(t("an_error_occurred"));
      setDisabled(false);
      console.log(error);
    }
  };

  return (
    <div className="position_fixed">
      <div className="div_position_fixed">
        <div className="verification_code">
          {resend ? (
            <button
              className="button"
              disabled={isDisabled}
              onClick={handleSendTokinUser}
            >
              {isDisabled ? t("waiting") : t("resend")}
            </button>
          ) : (
            <Timer setResend={setResend} time={time} setTime={setTime} />
          )}

          <p>{t("verification_code_email")}</p>

          <form onSubmit={onSubmit}>
            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={5}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />

            <button
              type="submit"
              className="button"
              disabled={isDisabled}
              style={{ opacity: isDisabled ? 0.3 : "" }}
            >
              {isDisabled ? t("waiting") : t("submit")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OtpEmail;
