import React, { useEffect, useRef, useState } from "react";
import ViewData from "../../../../components/services/ViewData";
import Loading from "../../../../components/loading/Loading";
import { toast } from "react-toastify";
import DeleteData from "../../../../components/services/DeleteData";
import { MdOutlineEdit, MdOutlineDateRange } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";
import { FaEye } from "react-icons/fa";
import UpdateChampionship from "./UpdateChampionship";

const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

const AllChampionship = () => {
  const [viewChampionship, setviewChampionship] = useState([]);
  const [championshipCount, setChampionshipCount] = useState(0);
  const [isDisabledLoadMore, setDisabledLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const [itemID, setItemID] = useState(false);
  const [viewItemID, setViewItemID] = useState(false);
  const trueRef = useRef(true);

  const dataChampionship = async () => {
    setLoading(true);
    setCurrentPage(1);

    try {
      const result = await ViewData("championship/championship.php");
      setviewChampionship(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataChampionshipCount = async () => {
    setLoading(true);

    try {
      const result = await ViewData("championship/championship_count.php");
      setChampionshipCount(result.request);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataChampionshipLoadMore = async () => {
    setDisabledLoadMore(true);
    try {
      const result = await ViewData(
        "championship/championship.php",
        null,
        currentPage + 1
      );
      setTimeout(() => {
        setviewChampionship((prevData) => prevData.concat(result));

        setDisabledLoadMore(false);

        setCurrentPage((prevPage) => prevPage + 1);
        setLoading(false);
      }, 2000);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      dataChampionship();
      dataChampionshipCount();
      trueRef.current = false;
    }
  }, []);

  const handleDelete = async (itemId) => {
    try {
      const result = await DeleteData("championship.php", itemId);

      if (result.request == "successfully") {
        toast.success("Deleted successfully");
        dataChampionship();
        dataChampionshipCount();
      } else {
        toast.error("An error occurred");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleView = async (itemId) => {
    window.open(`/championship/${itemId}`, "_blank");
  };

  const handleUpdate = async (itemId) => {
    setItemID(itemId);
    setViewItemID(true);
  };

  // console.log(viewChampionship);
  // console.log(championshipCount);

  return (
    <>
      {viewItemID && (
        <UpdateChampionship
          dataChampionship={dataChampionship}
          dataChampionshipCount={dataChampionshipCount}
          itemId={itemID}
          setViewItemID={setViewItemID}
        />
      )}

      <div>
        <h1>All Championship</h1>

        {error ? (
          "Error"
        ) : load ? (
          <Loading />
        ) : (
          <div className="full_championship">
            <div className="all_championship">
              {viewChampionship && viewChampionship.length > 0 ? (
                viewChampionship?.map((item, i) => (
                  <div className="item_championship" key={i}>
                    <img
                      src={`${LinkLikeBachend}${item.img}`}
                      alt={item.title_en}
                    />

                    {item.certainly_or_soon == 0 && (
                      <div className="soon">
                        <small>Coming SOON</small>
                      </div>
                    )}

                    {item.certainly_or_soon != 0 &&
                      item.number_subscribers == 0 && (
                        <div className="number_subscribers">
                          <small>Full</small>
                        </div>
                      )}

                    <small className="small_1">{item.title_en}</small>
                    <small>
                      <MdOutlineDateRange /> {item.date_add}
                    </small>

                    <div className="submit_delete">
                      <MdOutlineEdit
                        onClick={() => handleUpdate(item.id)}
                        className="delete"
                      />

                      <FaEye
                        onClick={() => handleView(item.id)}
                        className="delete"
                      />

                      <IoMdCloseCircle
                        onClick={() => handleDelete(item.id)}
                        className="delete"
                      />
                    </div>
                  </div>
                ))
              ) : (
                <h1>No Data</h1>
              )}
            </div>

            <div className="div_load_more">
              {viewChampionship &&
                viewChampionship.length > 0 &&
                parseInt(championshipCount) !== viewChampionship.length && (
                  <button
                    className="button"
                    onClick={dataChampionshipLoadMore}
                    disabled={isDisabledLoadMore}
                    style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
                  >
                    {!isDisabledLoadMore ? "More" : "loading..."}
                  </button>
                )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AllChampionship;
