import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Timer = ({ setResend, time, setTime }) => {
  const [seconds, setSeconds] = useState(time);
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(interval);
        setResend(true);
        setTime(time * 2);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds, setResend]);

  const formatTime = (time) => {
    return time < 10 ? `${time}` : `${time}`;
  };

  return (
    <>
      {seconds !== 0 && (
        <p style={{ opacity: "0.7" }}>
          {t("resend_verification_code_after")}:{" "}
          {formatTime(Math.floor(seconds / 60))}:{formatTime(seconds % 60)}
        </p>
      )}
    </>
  );
};

export default Timer;
