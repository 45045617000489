import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Fieldset from "../../components/input/Fieldset";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../components/context/AppProvider";
import { NavLink, useNavigate } from "react-router-dom";
import OtpEmailForget from "./OtpEmailForget";
import ViewData from "../../components/services/ViewData";

const ForgotPassword = () => {
  const methods = useForm();
  const { t } = useTranslation();
  const [isDisabled, setDisabled] = useState(false);
  const [otpEmailOpen, setOtpEmailOpen] = useState(false);
  const [emailUser, setEmailUser] = useState("");
  const { user } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user != null) {
      navigate("/user-dashboard", { replace: true });
    }
  }, [user]);

  const onSubmit = async (data, e) => {
    setDisabled(true);

    try {
      const result = await ViewData(
        "send_email_otp_forgot.php",
        data.email_user
      );

      setDisabled(false);

      if (result == "successfully") {
        toast.success(t("verification_code_email"));

        setEmailUser(data.email_user);
        setOtpEmailOpen(true);
      } else if (result == "user_incorrect") {
        toast.warning(t("user_not_registered"));
      } else {
        toast.warning(t("an_error_occurred"));
        console.log(result);
      }
    } catch (error) {
      toast.error(t("an_error_occurred"));
      setDisabled(false);
      console.log(error);
    }
  };

  return (
    <>
      {otpEmailOpen && (
        <OtpEmailForget
          setOtpEmailOpen={setOtpEmailOpen}
          emailUser={emailUser}
        />
      )}

      <div className="pages">
        <div className="pages support">
          <div className="left_right">
            <div className="right">
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Fieldset
                    type="email"
                    name="email_user"
                    placeholder={t("email")}
                    required={true}
                  />

                  <button
                    type="submit"
                    className="button"
                    disabled={isDisabled}
                    style={{ opacity: isDisabled ? 0.3 : "" }}
                  >
                    {isDisabled ? t("waiting") : t("send_message")}
                  </button>

                  <NavLink to="/login">{t("login")}</NavLink>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
