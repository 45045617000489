import React, { useEffect, useRef, useState } from "react";
import ViewDataCategory from "../../../components/services/ViewDataCategory";
import Loading from "../../../components/loading/Loading";
import all_category from "../../../img/all_category.webp";
import { NavLink } from "react-router-dom";
import { SiMicrosoftexcel } from "react-icons/si";
const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

const AdminPurchaseHistory = () => {
  const [orders, setOrders] = useState([]);
  const [ordersExcel, setOrdersExcel] = useState("");
  const [ordersCount, setOrdersCount] = useState(0);
  const [isDisabledLoadMore, setDisabledLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentType, setPayment_type] = useState("0");
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const trueRef = useRef(true);

  const dataOrders = async (paymentType) => {
    setLoading(true);
    setCurrentPage(1);
    // console.log(paymentType);

    try {
      const result = await ViewDataCategory(
        "orders/order.php",
        null,
        null,
        paymentType
      );
      setOrders(result.data);
      setOrdersExcel(result.excelDownload);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataOrdersCount = async (paymentType) => {
    // console.log(paymentType);

    try {
      const result = await ViewDataCategory(
        "orders/order_count.php",
        null,
        null,
        paymentType
      );

      setOrdersCount(result.request);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataOrdersLoadMore = async () => {
    setDisabledLoadMore(true);
    try {
      const result = await ViewDataCategory(
        "orders/order.php",
        null,
        currentPage + 1,
        paymentType
      );

      dataOrdersCount();

      setTimeout(() => {
        setOrders((prevData) => prevData.concat(result.data));

        setDisabledLoadMore(false);

        setCurrentPage((prevPage) => prevPage + 1);
        setLoading(false);
      }, 2000);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      dataOrders();
      dataOrdersCount();

      trueRef.current = false;
    }
  }, []);

  // console.log(orders);
  // console.log(ordersCount);
  // console.log(ordersExcel);

  return (
    <div>
      <h1>Purchase History</h1>

      <div>
        {error ? (
          "Error"
        ) : load ? (
          <Loading />
        ) : (
          <div className="full_championship">
            <div className="categories">
              <button
                className={`category ${
                  paymentType === "0" ? "active_category" : ""
                } `}
                onClick={() => {
                  setPayment_type("0");
                  dataOrders("0");
                  dataOrdersCount("0");
                }}
                disabled={isDisabledLoadMore}
                style={{ opacity: isDisabledLoadMore ? 0.3 : "" }}
              >
                <img src={all_category} alt="all_category" />
                Championships
              </button>
              <button
                className={`category ${
                  paymentType === "1" ? "active_category" : ""
                } `}
                onClick={() => {
                  setPayment_type("1");
                  dataOrders("1");
                  dataOrdersCount("1");
                }}
                disabled={isDisabledLoadMore}
                style={{ opacity: isDisabledLoadMore ? 0.3 : "" }}
              >
                <img src={all_category} alt="all_category" />
                store
              </button>

              <a
                href={`${LinkLikeBachend}${ordersExcel}`}
                className="category"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SiMicrosoftexcel />
              </a>
            </div>
            <div className="orders">
              {orders && orders.length > 0
                ? orders?.map((item, i) => {
                    const infoC = item.requestData.customer;

                    return (
                      <div className="order" key={i}>
                        <>
                          {item.payment_status === "1" ? (
                            <div className="successful_payment">
                              <small>payment status: successful payment</small>
                            </div>
                          ) : (
                            <div className="failed_payment">
                              <small>payment status: failed payment</small>
                            </div>
                          )}
                        </>

                        <div className="all_product">
                          <div className="product">
                            <small>payment Date: {item.date_add}</small>

                            <p>
                              payment code:{" "}
                              <span>{item.requestData.reference.id}</span>
                            </p>

                            <p>total: {item.requestData.order.amount} KWD</p>
                          </div>

                          {/* in cliente */}
                          <div className="product">
                            <b>user details</b>
                            <p>
                              full name: <span>{infoC.name}</span>
                            </p>
                            <p>
                              email: <span>{infoC.email}</span>
                            </p>
                            <p>
                              phone number: <span>{infoC.mobile}</span>
                            </p>
                            {item.payment_type === "0" && (
                              <>
                                <p>
                                  gender user: <span>{infoC.gender_user}</span>
                                </p>
                                <p>
                                  <span>{infoC.team_or_individual_user}</span>
                                </p>
                              </>
                            )}
                            {item.payment_type === "1" && (
                              <>
                                <p>
                                  recipient address:{" "}
                                  <span>{infoC.address}</span>
                                </p>

                                <p>
                                  size: <span>{infoC.size}</span>
                                </p>
                              </>
                            )}

                            {infoC.id !== undefined && (
                              <NavLink
                                className="button"
                                to={`/dashboard/admin-customer/${infoC.id}`}
                              >
                                customer details
                              </NavLink>
                            )}
                          </div>

                          {/* in answer */}

                          {item.payment_type === "0" && (
                            <div className="product">
                              {item.answerChampion.length > 0 &&
                                item.answerChampion.map((answer, i) => (
                                  <p key={i}>
                                    {answer.question}
                                    <span>{answer.answer}</span>
                                  </p>
                                ))}
                            </div>
                          )}

                          {/* in products */}
                          <div className="product">
                            {item.payment_type === "1"
                              ? "products details"
                              : ""}
                            {item.requestData.products?.map(
                              (product, index) => (
                                <div key={index} className="product">
                                  {item.payment_type === "0" ? (
                                    <NavLink
                                      className="button"
                                      to={`/championship/${product.id}`}
                                    >
                                      tournament details
                                    </NavLink>
                                  ) : (
                                    item.payment_type === "1" && (
                                      <NavLink
                                        className="button"
                                        to={`/store/${product.id}`}
                                      >
                                        product details
                                      </NavLink>
                                    )
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : "No data"}
            </div>

            <div className="div_load_more">
              {orders &&
                orders?.length > 0 &&
                parseInt(ordersCount) !== orders?.length && (
                  <button
                    className="button"
                    onClick={dataOrdersLoadMore}
                    disabled={isDisabledLoadMore}
                    style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
                  >
                    {!isDisabledLoadMore ? "more" : "loading"}
                  </button>
                )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminPurchaseHistory;
