import React, { useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";

const Text = ({
  name,
  type,
  readOnly,
  required,
  placeholder,
  className,
  value,
  idValiue,
}) => {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    if (value) {
      setValue(name, value);
    }
  }, [value]);

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <input
            {...field}
            type={type}
            readOnly={readOnly}
            required={required}
            placeholder={placeholder}
            className={className}
            id={idValiue}
          />
        )}
      />
    </>
  );
};

export default Text;
