import React, { useState } from "react";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ViewData from "../../components/services/ViewData";
import UpdateData from "../../components/services/UpdateData";
import Timer from "../../components/timer/Timer";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const OtpEmailForget = ({ setOtpEmailOpen, emailUser }) => {
  const [isDisabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [otp, setOtp] = useState("");
  const [resend, setResend] = useState(false);
  const [time, setTime] = useState(60);
  const [typeText, setTypeText] = useState("password");

  const handleChangepPassword = () => {
    typeText == "password" ? setTypeText("text") : setTypeText("password");
  };

  const handleSendTokinUser = async () => {
    setDisabled(true);

    try {
      const result = await ViewData("send_email_otp_forgot.php", emailUser);

      setDisabled(false);
      if (result == "successfully") {
        toast.success(t("verification_code_email"));
      } else if (result == "user_incorrect") {
        toast.warning(t("user_not_registered"));
      } else {
        toast.warning(t("an_error_occurred"));
        console.log(result);
      }
    } catch (error) {
      toast.error(t("an_error_occurred"));
      setDisabled(false);
      console.log(error);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);

    try {
      const result = await UpdateData("change_password.php", e, otp);

      setDisabled(false);

      if (result.request == "successfully") {
        toast.success(t("password_changed_successfully"));
        setOtpEmailOpen(false);
        navigate("/login");
      } else if (result.request == "verification_code_invalid") {
        toast.warning(t("verification_code_invalid"));
      } else if (result.request == "user_incorrect") {
        toast.warning(t("user_not_registered"));
      } else {
        toast.error(t("an_error_occurred"));
        console.log(result);
      }
    } catch (error) {
      toast.error(t("an_error_occurred"));
      setDisabled(false);
      console.log(error);
    }
  };

  return (
    <div className="position_fixed">
      <div className="div_position_fixed">
        <div className="verification_code">
          {resend ? (
            <button
              className="button"
              disabled={isDisabled}
              onClick={handleSendTokinUser}
            >
              {isDisabled ? t("waiting") : t("resend")}
            </button>
          ) : (
            <Timer setResend={setResend} time={time} setTime={setTime} />
          )}

          <p>{t("change_password")}</p>

          <form onSubmit={onSubmit}>
            <input type="hidden" value={emailUser} name="email_user" />
            <OTPInput
              name="otp"
              value={otp}
              onChange={setOtp}
              numInputs={5}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />

            <div className="password_eye">
              <input
                type={typeText}
                name="password_user"
                placeholder={t("password")}
                required={true}
                className="input"
              />

              {typeText == "password" ? (
                <FaEye onClick={handleChangepPassword} />
              ) : (
                <FaEyeSlash onClick={handleChangepPassword} />
              )}
            </div>

            <button
              type="submit"
              className="button"
              disabled={isDisabled}
              style={{ opacity: isDisabled ? 0.3 : "" }}
            >
              {isDisabled ? t("waiting") : t("submit")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OtpEmailForget;
