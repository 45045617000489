import React, { useEffect, useRef, useState } from "react";
import "./help.css";
import ViewData from "../../../components/services/ViewData";
import Loading from "../../../components/loading/Loading";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import UpdateData from "../../../components/services/UpdateData";
import Fieldset from "../../../components/input/Fieldset";

const Help = () => {
  const [dataSupport, setDataSupport] = useState();
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const [isDisabled, setDisabled] = useState(false);
  const methods = useForm();
  const trueRef = useRef(true);

  const submitData = async () => {
    setLoading(true);
    try {
      const result = await ViewData("support.php");
      setDataSupport(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      submitData();
      trueRef.current = false;
    }
  }, []);

  // console.log(dataSupport);

  const onUpdate = async (data, e) => {
    setDisabled(true);

    try {
      const result = await UpdateData("support.php", e);

      setDisabled(false);

      if (result.request == "successfully") {
        setDisabled(false);

        toast.success("Updated successfully");

        submitData();
      } else {
        toast.warning("An problem occurred");

        setDisabled(false);
        console.log(result);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An problem occurred");

      setDisabled(false);
    }
  };

  return (
    <div className="page_view_help">
      <h1>Help</h1>

      {error ? (
        "Error"
      ) : load ? (
        <Loading />
      ) : (
        <div className="form view_help_list">
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onUpdate)}
              encType="multipart/form-data"
              className="store_category_item"
            >
              <div className="inputBox">
                <Fieldset
                  name="phone_number_1"
                  type="text"
                  readOnly={false}
                  required={false}
                  placeholder="phone number"
                  className="input"
                  value={dataSupport.phone_number_1}
                />
              </div>

              <div className="inputBox">
                <Fieldset
                  name="phone_number_2"
                  type="text"
                  readOnly={false}
                  required={false}
                  placeholder="phone number"
                  className="input"
                  value={dataSupport.phone_number_2}
                />
              </div>

              <div className="inputBox">
                <Fieldset
                  name="email_1"
                  type="email"
                  readOnly={false}
                  required={false}
                  placeholder="email"
                  className="input"
                  value={dataSupport.email_1}
                />
              </div>

              <div className="inputBox">
                <Fieldset
                  name="email_2"
                  type="email"
                  readOnly={false}
                  required={false}
                  placeholder="email"
                  className="input"
                  value={dataSupport.email_2}
                />
              </div>

              <div className="inputBox">
                <Fieldset
                  name="whats_app"
                  type="url"
                  readOnly={false}
                  required={false}
                  placeholder="whatsApp"
                  className="input"
                  value={dataSupport.whats_app}
                />
              </div>

              <div className="submit_delete">
                <input
                  type="submit"
                  style={{
                    opacity: isDisabled ? 0.3 : 1,
                    background: "var(--bl)",
                  }}
                  disabled={isDisabled}
                  value={isDisabled ? "Loading..." : "Update"}
                  className="button"
                />
              </div>
            </form>
          </FormProvider>
        </div>
      )}
    </div>
  );
};

export default Help;
