import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Fieldset from "../../components/input/Fieldset";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import CreateData from "../../components/services/CreateData";
import Text from "../../components/input/Text";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useAppContext } from "../../components/context/AppProvider";
import { NavLink, useNavigate } from "react-router-dom";
import OtpEmail from "./OtpEmail";

const Login = () => {
  const methods = useForm();
  const { t } = useTranslation();
  const [typeText, setTypeText] = useState("password");
  const [isDisabled, setDisabled] = useState(false);
  const [otpEmailOpen, setOtpEmailOpen] = useState(false);
  const [tokinUser, setTokinUser] = useState("");
  const { loginUser, user } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user != null) {
      navigate("/user-dashboard", { replace: true });
    }
  }, [user]);

  const handleChangepPassword = () => {
    typeText == "password" ? setTypeText("text") : setTypeText("password");
  };

  const onSubmit = async (data, e) => {
    setDisabled(true);

    try {
      const result = await CreateData("login_user.php", e);

      setDisabled(false);

      if (result.request == "successfully") {
        toast.success(t("logged_in_successfully"));

        loginUser(result.newtoken);
      } else if (result.request == "user_incorrect") {
        toast.warning(t("user_not_registered"));
      } else if (result.request == "password_incorrect") {
        toast.warning(t("the_password_incorrect"));
      } else if (result.request == "email_confirmation") {
        toast.warning(t("email_not_confirmation"));
        setTokinUser(result.token);

        setOtpEmailOpen(true);
      } else {
        toast.warning(t("an_error_occurred"));
        console.log(result);
      }
    } catch (error) {
      toast.error(t("an_error_occurred"));
      setDisabled(false);
      console.log(error);
    }
  };

  return (
    <>
      {otpEmailOpen && (
        <OtpEmail setOtpEmailOpen={setOtpEmailOpen} tokinUser={tokinUser} />
      )}

      <div className="pages">
        <div className="pages support">
          <div className="left_right">
            <div className="right">
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Fieldset
                    type="email"
                    name="email_user"
                    placeholder={t("email")}
                    required={true}
                  />
                  <div className="password_eye">
                    <Text
                      type={typeText}
                      name="password_user"
                      placeholder={t("password")}
                      required={true}
                    />

                    {typeText == "password" ? (
                      <FaEye onClick={handleChangepPassword} />
                    ) : (
                      <FaEyeSlash onClick={handleChangepPassword} />
                    )}
                  </div>

                  <NavLink to="/forgot-password">
                    {t("forgot_your_password")}
                  </NavLink>

                  <button
                    type="submit"
                    className="button"
                    disabled={isDisabled}
                    style={{ opacity: isDisabled ? 0.3 : "" }}
                  >
                    {isDisabled ? t("waiting") : t("submit")}
                  </button>

                  <NavLink to="/register">{t("register_account")}</NavLink>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
