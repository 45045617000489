import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "./homeSlider.css";
import ViewData from "../../../components/services/ViewData";
import Loading from "../../../components/loading/Loading";

const HomeSlider = () => {
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const [viewAdminSlider, setAdminSlider] = useState([]);
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const trueRef = useRef(true);

  const dataAdminSlider = async () => {
    try {
      const result = await ViewData("slider.php");
      setAdminSlider(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      dataAdminSlider();
      trueRef.current = false;
    }
  }, []);

  return (
    <div className="home_slider">
      {error ? (
        "Error"
      ) : load ? (
        <Loading />
      ) : (
        <Swiper
          spaceBetween={30}
          effect={"fade"}
          loop={true}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Navigation, Pagination]}
        >
          {viewAdminSlider &&
            viewAdminSlider.length > 0 &&
            viewAdminSlider?.map((item, i) => (
              <SwiperSlide key={i}>
                <img
                  src={`${LinkLikeBachend}${item.img}`}
                  alt={`slider_${i}`}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      )}
    </div>
  );
};

export default HomeSlider;
