import React, { useEffect, useState } from "react";
import {
  IoLogoIonitron,
  IoIosLogOut,
  IoMdArrowDropdown,
  IoMdArrowDropleft,
} from "react-icons/io";
import { FaHome, FaHistory } from "react-icons/fa";
import { IoPeopleOutline, IoHelpOutline } from "react-icons/io5";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import "./navigation.css";
import { useAppContext } from "../../components/context/AppProvider";
import {
  MdMenuOpen,
  MdOutlineDataset,
  MdOutlinePrivacyTip,
  MdAddToPhotos,
} from "react-icons/md";
import { TbSocial } from "react-icons/tb";
import { FcAbout } from "react-icons/fc";
import { CiSettings } from "react-icons/ci";
import { TfiLayoutSlider } from "react-icons/tfi";
import { GiChampions } from "react-icons/gi";
import { TbCategoryPlus } from "react-icons/tb";
import { HiOutlineBuildingStorefront } from "react-icons/hi2";
import { AiOutlineGlobal } from "react-icons/ai";

const Navigation = () => {
  const [navActive, setNavActive] = useState(false);
  const [selectedItem, setSelectedItem] = useState(1);
  const [selectDropdown, setSelectDropdown] = useState({});
  const [isShow, setShow] = useState({});
  const context = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  const initialShowState = {};

  useEffect(() => {
    if (context.admin == null) {
      navigate("/admin", { replace: true });
    }

    const index = ulLiLinks.findIndex((item) => item.to === location.pathname);
    if (index !== -1) {
      if (index != 0) {
        setSelectedItem(index);
      }
    }

    Object.keys(navDropdown).forEach((key) => {
      initialShowState[key] = "hide";
    });
    setShow(initialShowState);
  }, []);

  const handleNavActive = () => {
    setNavActive(!navActive);
  };

  const ulLiLinks = [
    {
      dropdown: "",
      icon: <IoLogoIonitron />,
      title: "Admin",
      to: "/dashboard",
    },
    { dropdown: "", icon: <AiOutlineGlobal />, title: "WebSite", to: "/" },
    { dropdown: "", icon: <FaHome />, title: "Dashboard", to: "/dashboard" },
    {
      dropdown: "",
      icon: <IoPeopleOutline />,
      title: "Customers",
      to: "/dashboard/admin-customers",
    },
    {
      dropdown: "",
      icon: <FaHistory />,
      title: "Purchase History",
      to: "/dashboard/admin-purchase-history",
    },
    {
      dropdown: "true",
      icon: <MdOutlineDataset />,
      title: "Pages",
      to: "",
    },
    {
      dropdown: "true",
      icon: <GiChampions />,
      title: "Championship",
      to: "",
    },
    {
      dropdown: "true",
      icon: <HiOutlineBuildingStorefront />,
      title: "Store",
      to: "",
    },
    {
      dropdown: "",
      icon: <CiSettings />,
      title: "Settings",
      to: "/dashboard/settings",
    },
    { dropdown: "", icon: <IoIosLogOut />, title: "Sign Out", to: "signOut" },
  ];

  const navDropdown = {
    Pages: [
      {
        icon: <FcAbout />,
        title: "About Us",
        to: "/dashboard/admin-about-us",
      },
      {
        icon: <MdOutlinePrivacyTip />,
        title: "Privacy Policy",
        to: "/dashboard/admin-privacy-policy",
      },
      {
        icon: <IoHelpOutline />,
        title: "Help",
        to: "/dashboard/admin-help",
      },
      {
        icon: <TfiLayoutSlider />,
        title: "Slider Home",
        to: "/dashboard/admin-slider",
      },
      {
        icon: <TbSocial />,
        title: "Social Media",
        to: "/dashboard/admin-social-media",
      },
    ],
    Championship: [
      {
        icon: <GiChampions />,
        title: "All Championship",
        to: "/dashboard/admin-championship",
      },
      {
        icon: <MdAddToPhotos />,
        title: "Add Championship",
        to: "/dashboard/admin-add-championship",
      },
      {
        icon: <TbCategoryPlus />,
        title: "Championship Category",
        to: "/dashboard/admin-championship-category",
      },
    ],
    Store: [
      {
        icon: <HiOutlineBuildingStorefront />,
        title: "All Store",
        to: "/dashboard/admin-store",
      },
      {
        icon: <MdAddToPhotos />,
        title: "Add Store",
        to: "/dashboard/admin-add-store",
      },
      {
        icon: <TbCategoryPlus />,
        title: "Store Category",
        to: "/dashboard/admin-store-category",
      },
    ],
  };

  const handleClickSelected = (index) => {
    setSelectedItem(index);
  };

  const handleSignOut = () => {
    context.logOutAdmin();
  };

  const handlehandleDropdown = (dropdown) => {
    for (const key in navDropdown) {
      if (key === dropdown) {
        setSelectDropdown(navDropdown[key]);
        break;
      }
    }

    setShow((prevShow) => ({
      ...Object.fromEntries(
        Object.entries(prevShow)?.map(([key, value]) => [key, "hide"])
      ),
      [dropdown]: prevShow[dropdown] === "hide" ? "show" : "hide",
    }));
  };

  return (
    <div className="container_admin">
      <section className="section_admin">
        <Outlet />
      </section>

      <div className="navbar_admin">
        <MdMenuOpen onClick={handleNavActive} className="MdMenuOpen" />
        <div className={`navigation ${navActive ? "" : "active"}`}>
          <ul>
            {ulLiLinks?.map((link, i) => (
              <li
                key={i}
                className={selectedItem == i ? "hovered" : ""}
                onClick={() => handleClickSelected(i)}
              >
                {link.to == "WebSite" ? (
                  <a href={link.to} target="_blank">
                    <span className="icon">{link.icon}</span>
                    <span className="title">{link.title}</span>
                  </a>
                ) : link.to == "signOut" ? (
                  <a className="signOut" onClick={handleSignOut}>
                    <span className="icon">{link.icon}</span>
                    <span className="title">{link.title}</span>
                  </a>
                ) : link.dropdown == "true" ? (
                  <>
                    <a
                      className="dropdown"
                      onClick={() => handlehandleDropdown(link.title)}
                    >
                      <span className="icon">{link.icon}</span>
                      <span className="title">{link.title}</span>
                      {isShow[link.title] == "hide" ? (
                        <IoMdArrowDropleft className="IoMdArrowDropleft" />
                      ) : (
                        <IoMdArrowDropdown className="IoMdArrowDropleft" />
                      )}
                    </a>

                    <div className={`dropdown-container ${isShow[link.title]}`}>
                      {selectDropdown.length > 0 &&
                        selectDropdown?.map((item, i) => (
                          <NavLink to={item.to} key={i}>
                            <span className="icon">{item.icon}</span>
                            <span className="title">{item.title}</span>
                          </NavLink>
                        ))}
                    </div>
                  </>
                ) : (
                  <NavLink to={link.to}>
                    <span className="icon">{link.icon}</span>
                    <span className="title">{link.title}</span>
                  </NavLink>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
