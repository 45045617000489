import React from "react";
import HomeSlider from "./homeSlider/HomeSlider";
import HomeChampionship from "../championship/HomeChampionship";

const Home = () => {
  return (
    <div className="home">
      <HomeSlider />
      <HomeChampionship />
    </div>
  );
};

export default Home;
