import React, { useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";

const PhoneInputN = ({ name, required, placeholder, className, value }) => {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    if (value) {
      setValue(name, value);
    }
  }, [value]);

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <PhoneInput
            {...field}
            placeholder={placeholder}
            country={"kw"}
            className={className}
            inputProps={{
              required: required,
              name: name,
            }}
          />
        )}
      />
    </>
  );
};

export default PhoneInputN;
