import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { IoMdCloseCircle } from "react-icons/io";
import ViewData from "../../../../components/services/ViewData";
import Loading from "../../../../components/loading/Loading";
import { toast } from "react-toastify";
import Img from "../../../../components/input/Img";
import Text from "../../../../components/input/Text";
import JoditEditorInput from "../../../../components/input/JoditEditorInput";
import Checkbox from "../../../../components/input/Checkbox";
import UpdateData from "../../../../components/services/UpdateData";

const UpdateStore = ({ dataStore, dataStoreCount, itemId, setViewItemID }) => {
  const [isDisabled, setDisabled] = useState(false);
  const methods = useForm();
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const [isBackgroundImgUpdate, setBackgroundImgUpdate] = useState("");
  const [viewStore, setViewStore] = useState([]);
  const [viewCategory, setCategory] = useState([]);
  const [viewCategoryChampion, setCategoryChampion] = useState([]);
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const trueRef = useRef(true);

  const handleFileChangeUpdate = (e) => {
    setBackgroundImgUpdate(URL.createObjectURL(e.target.files[0]));
  };

  const [editorConfigEN] = useState({
    placeholder: "",
    direction: "ltr",
    minHeight: "500",
  });

  const [editorConfigAR] = useState({
    placeholder: "",
    direction: "rtl",
    minHeight: "500",
  });

  const dataStoreID = async () => {
    setLoading(true);
    try {
      const result = await ViewData("store/store.php", itemId, null);
      setViewStore(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataStoreCategory = async () => {
    setLoading(true);

    try {
      const result = await ViewData("store_category.php");
      setCategory(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataCategoryChampion = async () => {
    setLoading(true);

    try {
      const result = await ViewData("admin_category_store.php", itemId);
      setCategoryChampion(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      dataStoreID();
      dataStoreCategory();
      dataCategoryChampion();
      trueRef.current = false;
    }
  }, []);

  const handleClose = async () => {
    dataStore();
    dataStoreCount();
    setViewItemID(false);
  };

  const onSubmit = async (data, e) => {
    setDisabled(true);

    try {
      const result = await UpdateData("store.php", e, itemId);

      setDisabled(false);

      if (result.request == "successfully") {
        setDisabled(false);

        toast.success("Updated successfully");

        methods.reset();
        setBackgroundImgUpdate("");

        handleClose();
      } else {
        toast.warning("An problem occurred");

        setDisabled(false);
        console.log(result);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An problem occurred");

      setDisabled(false);
    }
  };

  // console.log(viewStore);
  // console.log(viewCategory);
  // console.log(viewCategoryChampion);

  return (
    <div className="position_fixed">
      <IoMdCloseCircle onClick={handleClose} className="handleClose" />

      <>
        {error ? (
          "Error"
        ) : load ? (
          <Loading />
        ) : (
          <div className="div_position_fixed form add_store update_store">
            {viewStore &&
              viewStore.length > 0 &&
              viewStore?.map((item, i) => (
                <FormProvider {...methods} key={i}>
                  <form
                    onSubmit={methods.handleSubmit(onSubmit)}
                    encType="multipart/form-data"
                  >
                    <div className="background_upload">
                      <Img
                        name="img"
                        type="file"
                        accept="image/*"
                        required={false}
                        change={handleFileChangeUpdate}
                      />

                      <img
                        src={
                          isBackgroundImgUpdate != ""
                            ? isBackgroundImgUpdate
                            : `${LinkLikeBachend}${item.img}`
                        }
                        alt="background_upload"
                      />
                    </div>

                    <div className="inputBox">
                      <Text
                        name="title_en"
                        type="text"
                        readOnly={false}
                        required={true}
                        placeholder="text en"
                        className="input"
                        value={item.title_en}
                      />

                      <Text
                        name="title_ar"
                        type="text"
                        readOnly={false}
                        required={true}
                        placeholder="text ar"
                        className="input"
                        value={item.title_ar}
                      />

                      <div className="price_currency">
                        <Text
                          name="price"
                          type="number"
                          readOnly={false}
                          required={true}
                          placeholder="price"
                          className="input price"
                          value={item.price}
                        />

                        <span>KWD</span>
                      </div>
                    </div>

                    <fieldset>
                      <legend>description EN</legend>

                      <JoditEditorInput
                        name="description_en"
                        editorConfig={editorConfigEN}
                        value={item.description_en}
                      />
                    </fieldset>

                    <fieldset>
                      <legend>description AR</legend>

                      <JoditEditorInput
                        name="description_ar"
                        editorConfig={editorConfigAR}
                        value={item.description_ar}
                      />
                    </fieldset>

                    <fieldset className="Category">
                      <legend>Category</legend>

                      {viewCategory &&
                        viewCategory.length > 0 &&
                        viewCategory?.map((item, i) => {
                          const isChecked =
                            viewCategoryChampion &&
                            viewCategoryChampion.length > 0 &&
                            viewCategoryChampion?.some(
                              (champion) => champion.id_category === item.id
                            );

                          return (
                            <label htmlFor={`category_${item.id}`} key={i}>
                              <Checkbox
                                name={`category_${item.id}`}
                                className="input"
                                idValiue={`category_${item.id}`}
                                value={isChecked}
                              />

                              <small>{item.text_en}</small>
                              <small>{item.text_ar}</small>
                              <small>
                                <img
                                  src={`${LinkLikeBachend}${item.img}`}
                                  alt={item.text_en}
                                />
                              </small>
                            </label>
                          );
                        })}
                    </fieldset>

                    <input
                      type="submit"
                      style={{
                        opacity: isDisabled ? 0.3 : 1,
                        background: "var(--bl)",
                      }}
                      disabled={isDisabled}
                      value={isDisabled ? "Loading..." : "Update"}
                      className="button"
                    />
                  </form>
                </FormProvider>
              ))}
          </div>
        )}
      </>
    </div>
  );
};

export default UpdateStore;
