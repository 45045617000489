import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Text from "../../../../components/input/Text";
import background_upload from "../../../../img/background_upload.webp";
import Img from "../../../../components/input/Img";
import "./storeCategory.css";
import ViewStoreCategory from "./ViewStoreCategory";
import ViewData from "../../../../components/services/ViewData";
import Loading from "../../../../components/loading/Loading";
import CreateData from "../../../../components/services/CreateData";

const StoreCategory = () => {
  const [isDisabled, setDisabled] = useState(false);
  const methods = useForm();
  const [isBackgroundImg, setBackgroundImg] = useState("");
  const [viewCategory, setCategory] = useState([]);
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const trueRef = useRef(true);

  const handleFileChange = (e) => {
    setBackgroundImg(URL.createObjectURL(e.target.files[0]));
  };

  const dataStoreCategory = async () => {
    setLoading(true);

    try {
      const result = await ViewData("store_category.php");
      setCategory(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      dataStoreCategory();
      trueRef.current = false;
    }
  }, []);

  const onSubmit = async (data, e) => {
    setDisabled(true);

    try {
      const result = await CreateData("store_category.php", e);

      setDisabled(false);

      if (result.request == "successfully") {
        setDisabled(false);

        toast.success("Added successfully");

        methods.reset();
        setBackgroundImg("");
        dataStoreCategory();
      } else {
        toast.warning("An problem occurred");

        setDisabled(false);
        console.log(result);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An problem occurred");

      setDisabled(false);
    }
  };

  // console.log(viewCategory);

  return (
    <div className="form">
      <h1>Store Category</h1>

      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          encType="multipart/form-data"
        >
          <div className="background_upload">
            <Img
              name="img"
              type="file"
              accept="image/*"
              required={true}
              change={handleFileChange}
            />

            <img
              src={isBackgroundImg != "" ? isBackgroundImg : background_upload}
              alt="background_upload"
            />
          </div>

          <div className="inputBox">
            <Text
              name="text_en"
              type="text"
              readOnly={false}
              required={true}
              placeholder="Category text en"
              className="input"
            />
          </div>

          <div className="inputBox">
            <Text
              name="text_ar"
              type="text"
              readOnly={false}
              required={true}
              placeholder="Category text ar"
              className="input"
            />
          </div>

          <input
            type="submit"
            style={{ opacity: isDisabled ? 0.3 : 1, background: "var(--bl)" }}
            disabled={isDisabled}
            value={isDisabled ? "Loading..." : "Add"}
            className="button"
          />
        </form>
      </FormProvider>

      {error ? (
        "Error"
      ) : load ? (
        <Loading />
      ) : (
        <ViewStoreCategory
          viewCategory={viewCategory}
          dataStoreCategory={dataStoreCategory}
        />
      )}
    </div>
  );
};

export default StoreCategory;
