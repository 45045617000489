import React, { useState } from "react";
import { MdAccountCircle } from "react-icons/md";
import { CiLogin } from "react-icons/ci";
import { BsFillBasket3Fill } from "react-icons/bs";
import Cookies from "js-cookie";
import Basket from "./Basket";
import { useAppContext } from "../../context/AppProvider";
import { NavLink } from "react-router-dom";

const Icon = ({ setChecked }) => {
  const [openBasket, setOpenBasket] = useState(false);
  const { countBasket, logOutUser } = useAppContext();

  const handleBasket = () => {
    setOpenBasket(true);
  };

  const handlelogOutUser = () => {
    logOutUser();
  };

  return (
    <>
      {openBasket && <Basket setOpenBasket={setOpenBasket} />}
      {Cookies.get("login_user") ? (
        <CiLogin onClick={handlelogOutUser} />
      ) : (
        <NavLink to="login" onClick={() => setChecked(false)}>
          <MdAccountCircle />
        </NavLink>
      )}

      <div className="pasket_fill">
        <BsFillBasket3Fill onClick={handleBasket} />

        <span>{countBasket}</span>
      </div>
    </>
  );
};

export default Icon;
