import axios from "axios";
const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
const validation = process.env.REACT_APP_VALIDATION;

const DeleteData = async (urlPage, itemId) => {
  const formData = new FormData();
  formData.append("validation", validation);
  formData.append("itemId", itemId);

  try {
    const response = await axios.post(
      `${LinkLikeBachend}delete/${urlPage}`,
      formData
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default DeleteData;
