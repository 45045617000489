import React, { useCallback, useEffect, useRef, useState } from "react";
import "./navbar.css";
import { NavLink, Outlet } from "react-router-dom";
import Footer from "../footer/Footer";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../context/AppProvider";
import Icon from "./icon/Icon";
import ViewData from "../services/ViewData";

const Navbar = () => {
  const [isChecked, setChecked] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const { i18n, t } = useTranslation();
  const context = useAppContext();
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const [viewSettings, setSettings] = useState({});
  const trueRef = useRef(true);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  const handleToggle = () => {
    setChecked(!isChecked);
  };

  const links = [
    { to: "/", label: t("home") },
    { to: "/store", label: t("store") },
    { to: "/about-us", label: t("about_us") },
    { to: "/support", label: t("support_assistance") },
  ];

  const links_user = [
    { to: "user-dashboard", label: t("user_information") },
    { to: "purchase-history", label: t("purchase_history") },
  ];

  const dataSettings = async () => {
    try {
      const result = await ViewData("settings.php");
      setSettings(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      dataSettings();
      trueRef.current = false;
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const ChangeAR = () => {
    i18n.changeLanguage("ar");
    context.setDirection("rtl");
  };

  const ChangeEN = () => {
    i18n.changeLanguage("en");
    context.setDirection("ltr");
  };

  // console.log(viewSettings);

  return (
    <>
      <nav style={{ background: scrolling ? "var(--bl)" : "" }}>
        <input
          type="checkbox"
          id="nav-toggle"
          checked={isChecked}
          onChange={handleToggle}
        />
        <label htmlFor="nav-toggle" className="icon-burger">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </label>

        <div className="right" onClick={() => setChecked(false)}>
          {context.direction == "ltr" ? (
            <li className="button" onClick={ChangeAR}>
              AR
            </li>
          ) : (
            <li className="button" onClick={ChangeEN}>
              EN
            </li>
          )}

          <Icon setChecked={setChecked} />
        </div>

        <ul className="links" onClick={handleToggle}>
          {links?.map((item, i) => (
            <li key={i}>
              <NavLink onClick={handleToggle} to={item.to}>
                {item.label}
              </NavLink>
            </li>
          ))}

          {context.user &&
            links_user?.map((item, i) => (
              <li key={i}>
                <NavLink onClick={handleToggle} to={item.to}>
                  {item.label}
                </NavLink>
              </li>
            ))}
        </ul>

        <NavLink to="/" className="logo" onClick={() => setChecked(false)}>
          <img src={`${LinkLikeBachend}${viewSettings?.logo}`} alt="logo" />
        </NavLink>
      </nav>

      <Outlet />

      <Footer />
    </>
  );
};

export default Navbar;
