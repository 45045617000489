import axios from "axios";
const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
const validation = process.env.REACT_APP_VALIDATION;

const UpdateData = async (urlPage, e, itemId) => {
  const formData = new FormData(e.target);
  formData.append("validation", validation);

  if (itemId) {
    formData.append("itemId", itemId);
  }

  try {
    const response = await axios.post(
      `${LinkLikeBachend}update/${urlPage}`,
      formData
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default UpdateData;
