import React, { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { HandelUpdateOrder } from "../../components/handelPayment/HandelUpdateOrder";
import { NavLink } from "react-router-dom";
import { useAppContext } from "../../components/context/AppProvider";

const ErrorPayment = () => {
  const params = new URLSearchParams(window.location.search);
  const paymentId = params.get("requested_order_id");
  const { t } = useTranslation();
  const ref = useRef(true);
  const { user } = useAppContext();

  const updateOrder = async () => {
    try {
      const result = await HandelUpdateOrder(paymentId, 2);

      if (result.request === "successfully") {
        toast.warning(t("error_payment"));
      }

      // console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (ref.current === true) {
      ref.current = false;
      updateOrder();
    }
  }, []);

  return (
    <div className="pages">
      <div className="box">
        <div className="error alert">
          <div className="alert-body">{t("error_payment")} !</div>

          {user !== null ? (
            <NavLink to="/purchase-history" className="button">
              {t("purchase_history")}
            </NavLink>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorPayment;
